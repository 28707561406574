import React, { memo } from "react";
import Icon from "./Icons/Icon";
import "./LogInWithImage.scss";

function LogInWithImage(props) {
  const {
    doLogin,
    onChange,
    title,
    description,
    loginWithTitle,
    email,
    isMonitorLoginEnabled,
    loginOptions,
    backgroundImage,
    brandLogo,
    brandLogoLeftPanel,
    welcomeLabel = "Welcome!",
    welcomeSubLabel = "",
    onShowPasswordChange,
    showPassword,
    rememberMe,
    onRememberMeChange,
    isCopyRightText = false,
    copyrightText = "",
    hideForgotPassword,
    forgotPassword,
    forgotPasswordView,
    sendPasswordLink,
    backgroundImageOverlayColor,
    backgroundImageOverlayColorOpacity,
  } = props;

  const backgroundStyle = {};
  if (backgroundImage) {
    backgroundStyle.backgroundImage = `url(${backgroundImage})`;
  }
  const backgroundOverlayStyle = {
    backgroundColor: backgroundImageOverlayColor ? backgroundImageOverlayColor : "transparent",
    opacity: backgroundImageOverlayColorOpacity ? backgroundImageOverlayColorOpacity : 1,
    width: "100%",
    height: "100%",
  };

  const logo = brandLogo || "/img/logos/accenture-logo.svg";
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      doLogin();
    }
  };

  return (
    <div className="axiom-login-with-image">
      <div className="login-data-container">
        {brandLogoLeftPanel && (
          <div className="left-panel-login-brand-logo">
            <img src={brandLogoLeftPanel} className="image-login-logo" />
          </div>
        )}
        <div className="image-login-header">{welcomeLabel}</div>
        {welcomeSubLabel && <div className="image-login-subheader">{welcomeSubLabel}</div>}
        <div className="image-login-content">
          {isMonitorLoginEnabled && (<>
          {isMonitorLoginEnabled && forgotPasswordView ? (
            <>
              <div>
                <div className="image-login-label">Email ID</div>
                <div className="image-login-box">
                  <div className="image-login-input-logo">
                    <Icon icon={"ICON_USER"} className={"image-login-icon"} />
                  </div>
                  <div className="image-login-input-box">
                    <input
                      type={"text"}
                      className={"image-login-input"}
                      placeholder={"Enter your email address"}
                      autoFocus={true}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => onChange("email", e)}
                      value={email}
                    />
                  </div>
                </div>
              </div>
              <div tabIndex={0} className="image-login-btn" onClick={sendPasswordLink}>
                SUBMIT
              </div>
              <a tabIndex={0} className="login-form-forgot-password" onClick={forgotPassword}>
                Back to Login
              </a>
            </>
          ) : (
            <div>
              <div>
                <div className="image-login-label">Email ID</div>
                <div className="image-login-box">
                  <div className="image-login-input-logo">
                    <Icon icon={"ICON_USER"} className={"image-login-icon"} />
                  </div>
                  <div className="image-login-input-box">
                    <input
                      type={"text"}
                      className={"image-login-input"}
                      placeholder={"Username"}
                      autoFocus={true}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => onChange("email", e)}
                      value={email}
                    />
                  </div>
                </div>
                <div className="image-login-label">Password</div>
                <div className="image-login-box">
                  <div className="image-login-input-logo">
                    <Icon icon={"ICON_PASSWORD"} className={"image-login-icon"} />
                  </div>
                  <div className="image-login-input-box">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={"image-login-input"}
                      placeholder={"Password"}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => onChange("password", e)}
                    />
                    <label
                      className={"image-login-remember-me-label"}
                      onClick={() => onShowPasswordChange()}
                      tabIndex={0}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </label>
                  </div>
                </div>
                <div className="image-login-rememberme">
                  <div>
                    <input
                      type="checkbox"
                      className="image-login-rememberme-checkbox"
                      onChange={() => onRememberMeChange()}
                      defaultChecked={rememberMe}
                      id="image-login-rememberme-checkbox"
                    />
                    <label
                      tabIndex={0}
                      htmlFor="image-login-rememberme-checkbox"
                      className={"image-rememberme-label"}
                    >
                      Remember Me
                    </label>
                  </div>
                  {!hideForgotPassword && (
                    <a href="#" className="login-form-forgot-password" onClick={forgotPassword}>
                      Forgot Password?
                    </a>
                  )}
                </div>
              </div>
              <div
                tabIndex={0}
                aria-roledescription="button"
                className="image-login-btn"
                onClick={doLogin}
              >
                LOG IN
              </div>
            </div>
          )}
          {loginOptions && loginOptions.length > 0 && <div className="image-login-or-text">OR</div>}
          </>)}
          {loginOptions && loginOptions.length > 0 && (
            <div className="image-login-other">
              <div className="image-other-login-text">Login with {loginWithTitle}</div>
              <div className="other-login-form-option-wrapper">
                {loginOptions.map((option, index) => {
                  return (
                    <a className="login-form-option" href={option.uri} key={index}>
                      <img className="login-form-option-icon" src={option.logo} />
                    </a>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {isCopyRightText.toString() == "true" ? (
        <div className="copyright-login-bottom-container">{copyrightText}</div>
      ) : null}
      <div style={backgroundStyle} className="login-image-container">
        <div style={backgroundOverlayStyle}>
          <div className="login-brand-logo">
            <img src={logo} className="image-login-logo" />
          </div>
          <div className="login-brand-text">
            <div className="login-brand-title">{title}</div>
            <div className="login-brand-description">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(LogInWithImage);
