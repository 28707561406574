import React from "react";

import "./AxiomSnackBar.scss";

export default class AxiomSnackBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const typeClass = this.props.type ? this.props.type : "info";
    const positionClass = this.props.position ? this.props.position : "center";

    if (this.props.snackOpen) {
      setTimeout(() => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }, this.props.autoHideDuration || 5000);
    }

    return (
      <div
        className={`axiom-snackbar ${
          this.props.snackOpen ? "is-showing" : ""
        } ${typeClass} ${positionClass}`}
      >
        {this.props.message}
        {typeClass === "error" && this.props.errorCode === 504 && (
          <div className="axiom-snackbar-dismiss-text">
            &nbsp;
            <span className="reload-button" onClick={() => window.location.reload()}>
              {"Click here"}
            </span>
            &nbsp;to refresh the page.
          </div>
        )}
        {this.props.onClose && (
          <button className="axiom-snackbar-dismiss" onClick={this.props.onClose}>
            &times;
          </button>
        )}
      </div>
    );
  }
}
