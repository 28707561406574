import React from "react";
import AxiomApp from "./AxiomApp";
import AxiomLayoutComponent from "./AxiomLayoutComponent";
import "./i18n";
import { useTranslation } from "react-i18next";

export default function AxiomLayout(props) {
  const { t, i18n } = useTranslation(["translation", "kpitranslation"]);

  if (AxiomAPIs != null) {
    if (i18n.language === "en") {
      AxiomAPIs.translate = (x) => x;
    } else {
      AxiomAPIs.translate = t;
    }
  }

  if (props.layoutName === "AxiomApp") {
    return <AxiomApp {...props} i18n={i18n} />;
  } else {
    return <AxiomLayoutComponent {...props} i18n={i18n} />;
  }
}

if ("serviceWorker" in navigator && location.protocol === "https:") {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("service-worker.js")
      .then((registration) => {
        console.info("SW registered: ", registration);

        // To show a prompt message to user whenever there is an update of the service worker
        navigator.serviceWorker.addEventListener("controllerchange", function () {
          const notificationElement = document.createElement("div");
          const notificationActivateElement = document.createElement("button");

          notificationElement.classList.add("sw-update-message");
          notificationElement.innerHTML =
            "New app version is ready. Click on update button to apply.";

          notificationActivateElement.classList.add("sw-update-action");
          notificationActivateElement.innerHTML = "Update";
          notificationActivateElement.addEventListener("click", () => {
            window.location.reload();
          });

          notificationElement.appendChild(notificationActivateElement);
          document.body.appendChild(notificationElement);
        });
      })
      .catch((registrationError) => {
        console.error("SW registration failed: ", registrationError);
      });
  });
}
