import React from "react";
import "./BotResponseSQLScript.scss";

const BotResponseSQLScript = ({ responseObject }) => {
  const { resp: responseData } = responseObject;
  const { response_sqlquery_html } = responseData?.response;

  return (
    <div className="bot-response-sql-container">
      <div 
        className="response-content"
        dangerouslySetInnerHTML={{ __html: response_sqlquery_html }} 
      />
    </div>
  );
};

export default BotResponseSQLScript;
