import React, { memo, useEffect, useCallback, useState, useMemo } from "react";
import "./Chatbot.scss";

import { useAxiomState } from "__AxiomComponents/AxiomHooks/AxiomHooks";
import AxiomLoader from "__AxiomComponents/AxiomUI/AxiomLoader/AxiomLoader.jsx";
import Sidebar from "../Sidebar/Sidebar";
import { useAxiomAppContext } from "__AxiomComponents/AxiomHooks/AxiomHooks";
import Dialog from "../Dialog/Dialog";
import TextWrapper from "../TextWrapper/TextWrapper";
import AxiomDrawer from "../AxiomDrawer/AxiomDrawer.jsx";
import { AxiomAppContext } from "./AxiomAppContext.js";
// import AxiomAppBar from "../AxiomAppBar/AxiomAppBar.jsx";
import AxiomAppBar from "../AxiomAppBar/AxiomAppBar.jsx";
import ChatBody from "../ChatBody/ChatBody.js";
import { useWidthHeightCalculator } from "__AxiomComponents/AxiomHooks/useWidthHeightCalculator";
import RemToPx from "__AxiomComponents/RemToPx/RemToPx.js";
import AxiomToogle from "__AxiomComponents/AxiomToogle/AxiomToogle";

import ChatWindow2 from "../ChatWindow/ChatWindow2.js";
import BotResponseScreen from "../BotResponseSection/BotResponseScreen.jsx";

function Chatbot(props) {
  const { appSettings, userInfo } = useAxiomAppContext();
  const [queTextFromSidePanel, setQueTextFromSidePanel] = useState({ question: "" });
  const [state, dispatch] = useAxiomState(
    {
      loading: true,
      drawerState: false,
      questionAnswerArray: [],
      responseObject: {},
      showResponseDetailWindow: false,
      respScreenShow: false,
    },
    props.appName,
    props.appID,
    "Chatbot",
    props.componentID,
  );

  const closeResponseDetailWindow = useCallback(
    (resp) => {
      dispatch((prevState) => {
        return {
          ...prevState,
          showResponseDetailWindow:
            prevState?.responseObject?.type === resp.type &&
            resp.elementId === prevState?.responseObject?.elementId
              ? false
              : true,
          responseObject:
            prevState?.responseObject?.type === resp.type &&
            resp.elementId === prevState?.responseObject?.elementId
              ? {}
              : resp,
        };
      });
    },
    [dispatch],
  );

  const closeRespFromCloseBtn = useCallback(() => {
    dispatch((prevState) => {
      return {
        ...prevState,
        showResponseDetailWindow:false,
        responseObject:{},
        respScreenShow: !prevState.respScreenShow,
      };
    });
  }, [dispatch]);

  const context = useMemo(() => {
    return {
      appSettings: appSettings,
      userInfo: userInfo,
    };
  }, [userInfo, appSettings]);

  const dialogText = "View starred messages only";

  const drawerToggle = () => {
    dispatch({ drawerState: !state.drawerState });
  };

  const logout = () => {
    AxiomAPIs.logout(function () {});
    setTimeout(() => {
      location.reload();
    }, 300);
  };

  const redirectToApp = (data) => {
    window.open(location.origin + data.uri, "_self");
  };

  const onDrawerStateClosed = () => {
    dispatch({ drawerState: false });
  };

  useEffect(() => {
    if (state.currentPage) {
      dispatch(
        {
          KPIWiseConfigs: null,
        },
        "AxiomEventInfo",
      );
      dispatch(
        {
          currentPage: state.currentPage,
        },
        "AxiomEvent",
        "getPageDetails",
      );
    }
  }, [dispatch, state.currentPage]);

  const [contentRef, width, height] = useWidthHeightCalculator();

  const handleOnClick = useCallback((q) => {
    // console.log(q);
    // let qa = { question: "", answer: "" };
    // qa.question = q;
    // let qaarray = [...state.questionAnswerArray, qa];
    // dispatch({ questionAnswerArray: qaarray });

    // // Delay of 10 seconds before the second dispatch
    // setTimeout(() => {
    //   dispatch((prevstate) => {
    //     qaarray = [...prevstate.questionAnswerArray];
    //     qaarray[qaarray.length - 1].answer =
    //       "Within the context of Bahrain 2023, FITNESS Cereal 375g is under-performing with 80% target achievement with 10.8 Mn BHR sales till previous month.";
    //     return { ...prevstate, questionAnswerArray: qaarray };
    //   });
    // }, 5000);

    setQueTextFromSidePanel({ question: q });
  }, []);

  return (
    <div className="Chatbot">
      <AxiomAppContext.Provider value={context}>
        <AxiomAppBar
          userLogo={state.userLogo}
          // user={{ userName: state.userName, userEmailID: state.userEmailID }}
          title={appSettings.appDisplayName}
          logout={logout}
          userInfo={context.userInfo}
          showPrevious={false}
          onMenuClick={drawerToggle}
          appLogo={appSettings.appLogo}
        />
      </AxiomAppContext.Provider>
      {/* <AxiomDrawer
        // user={{ userName: state.userName, userEmailID: state.userEmailID }}
        isLogoutDisabled={true}
        isShowChangePassword={false}
        logout={logout}
        onItemClick={redirectToApp}
        appSettings={state.appSettings}
        onDrawerStateClosed={onDrawerStateClosed}
        toggleDrawer={drawerToggle}
        drawerState={state.drawerState}
        drawerStyle={"normal"}
      /> */}
      {state.drawerState && (
        <div className="sub-body-1" style={{ height: "100%", width: "30%" }}>
          <Sidebar
            appName={props.appName}
            appID={props.appID}
            componentID={props.componentID}
            onClick={handleOnClick}
            pastPromptLimit={appSettings.pastPromptLimit || 0}
            toggleDrawer={drawerToggle}
            isSidebarOpen={state.drawerState}
          />
        </div>
      )}
      <div
        className="main-body"
        ref={contentRef}
        style={{ display: "flex", height: "calc(100vh - 50px" }}
      >
        <div
          className={
            state.showResponseDetailWindow ? "sub-body-2" : "sub-body-2 chatbot-details-screen-off"
          }
          style={{ height: "100%", width: "100%" }}
        >
          <ChatWindow2
            appName={props.appName}
            appID={props.appID}
            componentID={props.componentID}
            userInfo={context.userInfo}
            queTextFromSidePanel={queTextFromSidePanel}
            chatHistoryLimit={appSettings.chatHistoryLimit || 0}
            welcomeText={appSettings.welcomeText || ""}
            closeResponseDetailWindow={closeResponseDetailWindow}
            startScreenQuesClick={handleOnClick}
            respScreenShow={state.respScreenShow}
          />
        </div>

        <div className={state.showResponseDetailWindow ? "resp-window open" : "resp-window hide"}>
          {state.showResponseDetailWindow && (
            <BotResponseScreen
              responseObject={state.responseObject}
              closeRespFromCloseBtn={closeRespFromCloseBtn}
            />
          )}
        </div>

        {/* <div className="sub-body-right" style={{ height: "100%", width: "70%" }}> */}

        {/* <div className="dialog-box" style={{ height: componentHeights.dialogHeight }}>
            <div className="Dialog">
              <div className="dialog-text-box">{dialogText}</div>
              <AxiomToogle />
            </div>
          </div> */}
        {/* <ChatBody
            appName={props.appName}
            appID={props.appID}
            componentID={props.componentID}
            height={componentHeights.chartBodyHeight}
            qaarray={state.questionAnswerArray}
          />
          <TextWrapper
            appName={props.appName}
            appID={props.appID}
            componentID={props.componentID}
            height={componentHeights.textBoxHeight}
            onClick={onClick}
          /> */}
        {/* </div> */}
      </div>
      {/* {state.loading && <AxiomLoader background={"rgba(255, 255, 255, 0.4)"} block=".Chatbot" />} */}

      {/* <Sidebar appName={props.appName} appID={props.appID} componentID={props.componentID} /> */}
      {/* <ChatWindow/> */}
    </div>
  );
}

export default memo(Chatbot);
