import { memo } from "react";
import "./AxiomSpinner.css";

function AxiomSpinner(props) {
  return (
    <svg
      className={"axiom-spinner" + (props.className || "")}
      style={{ width: props.spinnerWidth || 60 }}
      viewBox="25 25 50 50"
    >
      <circle
        stroke={props.spinnerColor || "var(--primary-color)"}
        className="axiom-spinner-circle"
        cx="50"
        cy="50"
        r="20"
      />
    </svg>
  );
}

export default memo(AxiomSpinner);
