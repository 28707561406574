import React, { memo, useCallback, useMemo } from "react";
//import "./MultiAreaChart.scss";
import ReactEcharts from "echarts-for-react";
import Utils from "__AxiomComponents/Utils.js";

function BarResponse(props) {
    const  sampleResponse = {
  "data": [
    {
      "year": 2023,
      "lastyearspent": "200000"
    },
    {
      "year": 2024,
      "lastyearspent": "200000"
    }
  ],
  "xAxisLabel": "xaxisLabel",
  "yAxisLabel": "yaxislabel",
  "yAxisDisplayFormat": "0,0.00MillionCaps",
  "xAxisDisplayFormat": "",
  "dimensionColumn": "year",
  "measureColumn": "lastyearspent",
  "charType":"distribution" 
}
// {
//   data= [
//     {
//       "year": 2023,
//       "lastyearspent": "200000"
//     },
//     {
//       "year": 2024,
//       "lastyearspent": "200000"
//     },
//     {
//       "year": 2025,
//       "lastyearspent": "200000"
//     },{
//       "year": 2026,
//       "lastyearspent": "200000"
//     },
//     {
//       "year": 2027,
//       "lastyearspent": "200000"
//     }
//   ],
//   xAxisLabel= "xaxisLabel",
//   yAxisLabel= "yaxislabel",
//   yAxisDisplayFormat= "0,0.00MillionCaps",
//   xAxisDisplayFormat= "",
//   dimensionColumn= "year",
//   measureColumn= "lastyearspent",
//   charType="distribution" 
// }

  const chartData = props.data;
  const {
    data,
    xAxisLabel,
    yAxisLabel,
    yAxisDisplayFormat,
    xAxisDisplayFormat,
    dimensionColumn,
    measureColumn,
    charType="distribution" 
  } = chartData;
 const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');;
  const utilsApplyFormat = useCallback((value, unit) => {
    return Utils.apply_format(value, unit);
  }, []);

  //let formattedX = value => utilsApplyFormat(value || 0, xKPI_Unit || "0,00");
  let formattedY = (value) => utilsApplyFormat(value || 0, yAxisDisplayFormat);

  let format = (params) => {
    return utilsApplyFormat(params.value || 0, yAxisDisplayFormat || "0,00");
  };
  let formatTooltip = (params) => {
    return (
      params.name +
      ": " +
      utilsApplyFormat(params.value || 0, yAxisDisplayFormat || "0,00")
    );
  };
  // const xScale = useScaleLinear(x_min, x_max, 0, chartWidth);
  // const yScale = useScaleLinear(y_min, y_max, chartHeight, 0);
  //const rScale = useScaleLinear(r_min, r_max, 8, 60);

  //let data = chartData && chartData.map((ele) => ele.value);
  let xAxisTicks = data && data.length && data.map((ele)=>ele[dimensionColumn])
  let chartValues = data && data.length && data.map((ele)=>ele[measureColumn])
  let option = {
    grid: [{ left: "5%", right: "5%", bottom: "12%", containLabel: true }],
    // title: {
    //   subtext: title,
    //   //textVerticalAlign :"top",
    //   //align:"left",
    //   //padding:"20px",
    //   left: "5%",
    //   subtextStyle: {
    //     fontSize: "14px",
    //     fontWeight: 500,
    //     color: "#333333",
    //   },
    // },
    xAxis: [
      {
        type: "category",
        name: xAxisLabel,
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
          height: "11px",
          color: "#808080",
          // fontFamily: 'Graphik',
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "10rem",
          align: "center",
        },
        axisLabel: {
          width: "45px",
          // fontFamily: 'Graphik',
          interval: 0,
          formatter: function (value) {
            return value.length > 14
              ? value
                  .split(" ")
                  .map((x) => x.trim())
                  .join("\n")
              : value;
          },
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "10rem",
          textAlign: "center",
          color: "#808080",
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#D8D8D8",
          },
          onZero: false,
        },
        data: xAxisTicks,
       
        width: "100",
        splitLine: {
          show: true,
          lineStyle: {
            color: "transparent",
          },
          alignWithLabel: true,
        },
        axisTick: {
          show: false,
        },
        gridIndex: 0,
      },
    ],
    yAxis: [
      {
        type: "value",
        position: "left",
        scale: true,
        alignTicks: true,
        name : yAxisLabel,
        nameTextStyle : {
          color: "#808080",
          // fontFamily: 'Graphik',
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "10rem",
          align: "left",
        },
        textStyle: {
          color: "#808080",
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#D8D8D8",
          },
        },
        axisTick: {
          show: true,
        },
        axisLabel: {
          formatter: formattedY,
          width: "40px",
          // fontFamily: 'Graphik',
          fontStyle: "normal",
          fontWeight: 10,
          fontSize: "10rem",
          textAlign: "right",
          color: "#808080",
        },
        axisPointer: {
          snap: true,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#D8D8D8",
            type: "dashed",
          },
        },
      },
    ],
    series: [
      {
        name: "Chart 1",
        type: "bar",
        barWidth: 20,
        data: chartValues,
        itemStyle: {
          color: color, // Set color for each point
        },
        cursor: "pointer",
        label: {
          show: true,
          position: "top",
          fontWeight: 500,
          fontSize: "10px",
          color: "#333333",
          formatter: format,
        },
        emphasis: {
          disabled: true,
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
      },
    ],
    tooltip: {
      trigger: "item",
      backgroundColor: "#f8f8f8",
      borderColor: "#808080",
      axisPointer: {
        type: "none",
      },
      formatter: formatTooltip,
    },
  };


  return (
    <div
      className="BarEchart"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {
        //!fetchingData &&
        option && (
          <ReactEcharts
            style={{
              height: "100%",
        width: "100%",
            }}
            option={option}
            key={`data_${xAxisTicks.length}`}
          />
        )
      }
    </div>
  );
}

export default memo(BarResponse);
