import React from "react";
import "./BotResponseTable.scss";
import numeral from "numeral";

const BotResponseTable = ({ responseObject }) => {
  const { resp: responseData } = responseObject;
  const { title_html, data: rows, headers } = responseData?.response?.response_table;

  console.log(responseData, title_html, rows, headers);

  // Function to format data based on the provided displayFormat
  const formatValue = (value, format) => {
    if (!isNaN(value)) {
      return numeral(value).format(format);
    }
    return value;
  };

  // Helper to find the min and max values for numeric columns with color coding enabled
  const getMinMaxValues = (rows, colKey) => {
    const numericValues = rows
      .filter((row) => row[colKey] !== "Total")
      .map((row) => row[colKey])
      .filter((val) => !isNaN(val)); // Remove non-numeric values
    return { min: Math.min(...numericValues), max: Math.max(...numericValues) };
  };

  // Create a color gradient from green (min) to red (max) for numeric columns
  const getColorForValue = (value, min, max) => {
    if (min === max) return "rgb(255, 0, 0)"; // If all values are the same, set to red
    const percentage = (value - min) / (max - min);
    const r = Math.floor(255 * (1 - percentage)); // Red increases with value
    const g = Math.floor(255 * percentage); // Green decreases with value
    return `rgb(${r}, ${g}, 0)`;
  };

  // Get min/max values for numericColorCode columns
  const colorCodingColumns = headers
    .filter((header) => header.numericColorCode)
    .map((header) => header.mapping);

  const minMaxValues = {};
  colorCodingColumns.forEach((colKey) => {
    minMaxValues[colKey] = getMinMaxValues(rows, colKey);
  });

  // Function to convert JSON data to CSV format
  const exportToCSV = () => {
    // Function to generate a unique filename with IST datetime
    const generateFileName = () => {
      const now = new Date();
      const istOffset = 0; // IST is UTC+5:30, 330 minutes ahead
      const istDate = new Date(now.getTime() + istOffset * 60 * 1000);

      return `table_data_${istDate.getFullYear()}${String(istDate.getMonth() + 1).padStart(2, "0")}${String(istDate.getDate()).padStart(2, "0")}_${String(istDate.getHours()).padStart(2, "0")}${String(istDate.getMinutes()).padStart(2, "0")}${String(istDate.getSeconds()).padStart(2, "0")}.csv`;
    };

    // Extract display names for header row in CSV
    const headerRow = headers.map((header) => header.displayName).join(",");

    // Extract data rows in CSV format
    const dataRows = rows
      .map((row) =>
        headers
          .map((header) => {
            const cell = row[header.mapping];
            return typeof cell === "number" ? cell.toString() : `"${cell}"`;
          })
          .join(","),
      )
      .join("\n");

    // Combine header and data rows
    const csvContent = `${headerRow}\n${dataRows}`;

    // Create a blob and generate a download link
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = generateFileName();
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  };

  return (
    <div className="bot-response-table-container">
      <div className="table-header">
        {title_html && <h2 className="table-title">{title_html}</h2>}
        <button className="download-btn" onClick={exportToCSV}>
          Download
        </button>
      </div>
      <div className="table-wrapper">
        <table className="fixed-column-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header.displayName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex} className={row.col1 === "Total" ? "total-row" : ""}>
                {headers.map((header, colIndex) => {
                  const cellValue = row[header.mapping];
                  const formattedValue = formatValue(cellValue, header.displayFormat);

                  // Apply color coding for numericColorCode columns
                  const style = {};
                  if (header.numericColorCode && !isNaN(cellValue) && row.col1 !== "Total") {
                    const { min, max } = minMaxValues[header.mapping];
                    style.color = getColorForValue(cellValue, min, max);
                  }

                  return (
                    <td
                      key={colIndex}
                      className={`${row.col1 === "Total" ? "total-first-cell" : ""} ${typeof cellValue === "number" ? "align-right" : "align-left"}`}
                      style={style}
                    >
                      {formattedValue}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BotResponseTable;
