import React from "react";
import PropTypes from "prop-types";

import Tile from "../Tile.jsx";

import { getBeginOfMonth, getEndOfMonth, getISOLocalMonth } from "../shared/dates";
import { formatMonth as defaultFormatMonth } from "../shared/dateFormatter";
import { tileProps } from "../shared/propTypes";

const className = "react-calendar__year-view__months__month";

const matchMonth = (months, date) => {
	if (months) {
		for (let i = 0; i < months.length; i++) {
			let d1 = months[i];
			let d2 = new Date(date);
			if (d1.month === d2.getMonth() + 1 && d1.year === d2.getFullYear()) {
				return true;
			}
		}
	}
	return false;
};
const Month = ({ classes, date, formatMonth, ...otherProps }) => (
	<Tile {...otherProps} classes={[...classes, matchMonth(otherProps.disabledMonths, date) ? "disabled_month" : "", className]} date={date} dateTime={`${getISOLocalMonth(date)}T00:00:00.000`} maxDateTransform={getEndOfMonth} minDateTransform={getBeginOfMonth} view="year">
		{formatMonth(date)}
	</Tile>
);

Month.propTypes = {
	...tileProps,
	formatMonth: PropTypes.func
};

Month.defaultProps = {
	formatMonth: defaultFormatMonth
};

export default Month;
