import React from "react";
// import HtmlResponse from './HtmlResponse';
import BarResponse from "./BarResponse";
import Grid from "../Grid/Grid";
import ScatterPlot from "../ScatterPlot/ScatterPlot";
import "./Response.scss";
import parse from "html-react-parser";

const Response = ({ responseType, responseData, responseText, responseHTML }) => {
  const renderResponseComponent = () => {
    console.log(responseType, responseData, responseText);
    switch (responseType) {
      case "text":
        return <div>{responseText}</div>;
      case "html":
        return <div className="htmlResponseDiv">{parse(responseHTML)}</div>;
      case "barChart":
        return (
          <div>
            <div>{responseText == "Response Unavailable" ? "" : responseText}</div>
            <div>{responseHTML == "Response Unavailable" ? "" :parse(responseHTML)}</div>
            <div style={{ height: "200px", width: "500px" }}>
              <BarResponse data={responseData} />
            </div>
          </div>
        );

      case "grid":
        return (
          <div>
            <div>{responseText == "Response Unavailable" ? "" : responseText}</div>
            <div>{responseHTML == "Response Unavailable" ? "" :parse(responseHTML)}</div>
            <div
              className="grid-response"
              style={{ height: `${(responseData.data.length + 3) * 20}px`, width: "500px" }}
            >
              <Grid data={responseData} />
            </div>
          </div>
        );

      case "scatterChart":
        return (
          <div>
            <div>{responseText == "Response Unavailable" ? "" : responseText}</div>
            <div>{responseHTML == "Response Unavailable" ? "" :parse(responseHTML)}</div>
            <div style={{ height: "300px", width: "700px" }}>
              <ScatterPlot data={responseData} />
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return <div className="Response">{renderResponseComponent()}</div>;
};

export default Response;
