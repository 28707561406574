import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import "./ChatWindow2.scss";
import chatWindow2Styles from "./ChatWindow2.scss"; // Import SCSS as a module - This is for dynamic html string class inclusion
import Dialog from "../Dialog/Dialog";
import Response from "../Responses/Response";
import { useAxiomState } from "__AxiomComponents/AxiomHooks/AxiomHooks";
import _ from "lodash";
import { FaHistory } from "react-icons/fa";

function ChatWindow2(props) {
  const handleClickVar = props.closeResponseDetailWindow;
  const [selectedPythonURLtoCall, setSelectedPythonURLtoCall] = useState("get_input");
  const [questionText, setQuestionText] = useState("props?.queTextFromSidePanel");
  const [showLandingScreen, setShowLandingScreen] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  console.log(questionText);
  const [answer, setAnswer] = useState({});

  const ansContRef = useRef(null);
  const endRef = useRef(null);
  const [state, dispatch] = useAxiomState(
    {
      questionId: "",
      showonlystarred: false,
    },
    props.appName,
    props.appID,
    "ChatWindow",
    props.componentID,
  );

  useEffect(() => {
    if (props?.queTextFromSidePanel) {
      setQuestionText(props.queTextFromSidePanel.question);
    }
  }, [props?.queTextFromSidePanel]);

  const handleClick = useCallback(
    (element, questionKey) => {
      let starselected = element.target.parentElement.querySelector(".starselected");
      let likeselected = element.target.parentElement.querySelector(".likeselected");
      let dislikeselected = element.target.parentElement.querySelector(".discardselected");
      let startStatus = starselected ? "true" : "false";
      let likeStatus = likeselected ? "true" : "false";
      let dislikeStatus = dislikeselected ? "true" : "false";
      if (element.target.className.includes("star")) {
        if (!starselected) {
          element.target.className = "starselected";
          startStatus = "true";
        } else {
          element.target.className = "star";
          startStatus = "false";
        }
      } else if (element.target.className.includes("like")) {
        if (dislikeselected) {
          // If dislike is selected, deselect it
          dislikeselected.className = "discard";
          dislikeStatus = "false";
        }
        if (!likeselected) {
          element.target.className = "likeselected";
          likeStatus = "true";
        } else {
          element.target.className = "like";
          likeStatus = "false";
        }
      } else if (element.target.className.includes("discard")) {
        if (likeselected) {
          // If like is selected, deselect it
          likeselected.className = "like";
          likeStatus = "false";
        }
        if (!dislikeselected) {
          element.target.className = "discardselected";
          dislikeStatus = "true";
        } else {
          element.target.className = "discard";
          dislikeStatus = "false";
        }
      }
      console.log(questionKey);
      dispatch(
        { loading: true, startStatus, likeStatus, dislikeStatus, questionId: questionKey },
        "AxiomEvent",
        "selectRatingOrLikeDislike",
      );
    },
    [dispatch],
  );

  const handleResponseButtonClick = useCallback(
    (elementObj, elementId, type, resp) => {
      let baseClass = "botResDetailBtnStyle01";
      let selectedClassName = `${baseClass} selected`;

      console.log(elementId);

      let respBtnSelected = elementObj.target.parentElement.querySelector(".selected");

      if (elementObj.target.className.includes("selected")) {
        elementObj.target.className = baseClass;
      } else {
        const elms = document.getElementsByClassName(selectedClassName);

        // Loop through each element and change the class name
        Array.from(elms).forEach((elm) => {
          // eslint-disable-next-line no-param-reassign
          elm.className = baseClass;
        });
        if (respBtnSelected) {
          respBtnSelected.className = baseClass;
        }
        elementObj.target.className = selectedClassName;

        console.log(selectedClassName);
      }
      handleClickVar({ type, resp, elementId });
    },
    [handleClickVar],
  );

  const toggleStarredMessage = (object) => {
    dispatch({
      showonlystarred: object.target.checked,
      questionList: null,
    });
  };

  useEffect(() => {
    if (!state.questionList) {
      dispatch(
        {
          limit: props.chatHistoryLimit,
          starred: state.showonlystarred,
        },
        "AxiomEvent",
        "mount",
      );
    }
  }, [state.questionList, dispatch, state.showonlystarred, props.chatHistoryLimit]);

  useEffect(() => {
    // console.log(props.respScreenShow)
    if (state.questionList) {
      if (ansContRef.current != null) {
        ansContRef.current.querySelectorAll(".abc").forEach((d) => d.remove());
        ansContRef.current.querySelectorAll(".answer-text").forEach((d) => d.remove());
        state.questionList.forEach((item, index) => {
          const tempDiv1 = document.createElement("div");
          tempDiv1.className = "abc";
          tempDiv1.innerHTML = `
          <div class="question-text">
            <div class="text-content">${item.QUESTIONTEXT}</div>
            <div class="question-text-logo-container" style="display: none;>
            <span class="question-text-logo" style="display: none;>${props.userInfo.name.charAt(0)}</span>
            </div>
          </div>
          `;

          ansContRef.current.appendChild(tempDiv1);
          let starClass = item.ISSTARRED == "true" ? "starselected" : "star";
          let likeClass = item.LIKED == "true" ? "likeselected" : "like";
          let discardClass = item.UNLIKED == "true" ? "discardselected" : "discard";
          const tempDiv = document.createElement("div");
          tempDiv.className = "answer-text";

          let addAnsRefResp = "";
          try {
            addAnsRefResp = JSON.parse(item?.RESPONSE);
          } catch (ex) {
            addAnsRefResp = {
              response: { response_type: "text", response_text: "Error in response format." },
            };
          }

          const isTblDataExists =
            addAnsRefResp.errResp !== true &&
            addAnsRefResp.response?.response_table?.data?.length > 0;

          let respBtnShow = "";
          if (isTblDataExists) {
            respBtnShow = `<button id=${"table" + item.QUESTIONKEY} class="botResDetailBtnStyle01">Table</button>
                <button id=${"chart" + item.QUESTIONKEY} class="botResDetailBtnStyle01">Chart</button>
                <button id=${"sqlscript" + item.QUESTIONKEY} class="botResDetailBtnStyle01">SQL Script</button>
        `;
          }

          tempDiv.innerHTML = `
          <div class="logo-wrapper">
            <div class="answer-text-logo"></div>
          </div>
          <div style="max-width:70%">
            <div class="text-content-ans">
              <div class="ans-content"> 
                <div class="response-content"></div>             
              </div>           
            </div>
            <div class="rating-text">
              <div class="botResDetailBtnContainer">
                <div class="right-btn-section">
                  ${respBtnShow}
                </div>
                <div class="left-btn-section">
                  <div class="disclaimer" style="display: none;">AI-generated content may be incorrect.</div>
                  <div id=${"star" + item.QUESTIONKEY} class=${starClass} style="display: none;"></div>
                  <div class="borderdivider" style="display: none;"></div>
                  <div id=${"like" + item.QUESTIONKEY} class=${likeClass}></div>
                  <div id=${"discard" + item.QUESTIONKEY} class=${discardClass}></div>
                </div>
              </div>
            </div>
          </div> `;

          ansContRef.current.appendChild(tempDiv);
          const tableElement = tempDiv.querySelector(`#table${item.QUESTIONKEY}`);
          const chartElement = tempDiv.querySelector(`#chart${item.QUESTIONKEY}`);
          const sqlScriptElement = tempDiv.querySelector(`#sqlscript${item.QUESTIONKEY}`);
          const ratingStarElement = tempDiv.querySelector(`#star${item.QUESTIONKEY}`);
          const ratingLikeElement = tempDiv.querySelector(`#like${item.QUESTIONKEY}`);
          const ratingDislikeElement = tempDiv.querySelector(`#discard${item.QUESTIONKEY}`);
          if (ratingStarElement) {
            ratingStarElement.addEventListener("click", (element) =>
              handleClick(element, item.QUESTIONKEY),
            );
            ratingLikeElement.addEventListener("click", (element) =>
              handleClick(element, item.QUESTIONKEY),
            );
            ratingDislikeElement.addEventListener("click", (element) =>
              handleClick(element, item.QUESTIONKEY),
            );
          }

          if (tableElement) {
            tableElement.addEventListener("click", (element) =>
              handleResponseButtonClick(element, item.QUESTIONKEY, "table", addAnsRefResp),
            );
            chartElement.addEventListener("click", (element) =>
              handleResponseButtonClick(element, item.QUESTIONKEY, "chart", addAnsRefResp),
            );
            sqlScriptElement.addEventListener("click", (element) =>
              handleResponseButtonClick(element, item.QUESTIONKEY, "sql", addAnsRefResp),
            );
            // thoughtProcessElement.addEventListener("click", (element) => handleResponseButtonClick(element, item.QUESTIONKEY, 'thought', JSON.parse(item.RESPONSE)));
          }

          // Define the handleClick function
          /*function handleClick(element,questionId) {
        let field=null,value='false';
          if(element.target.className.includes("star")){
            field = 'ISSTARRED';
            if(element.target.className=='star'){
              element.target.className='starselected';
             
              value='true';
            }else{
              element.target.className='star'
            }
            //dispatch({ loading: true,star: star,questionId:1234}, "AxiomEvent", "selectRatingOrLikeDislike");
          }else if(element.target.className.includes("like")){
             field = 'LIKED';
            if(element.target.className=='like'){
              element.target.className='likeselected';
             
              value='true';
            }else{
              element.target.className='like'
            }
          //  dispatch({ loading: true,like: like,questionId:1234}, "AxiomEvent", "selectRatingOrLikeDislike");
          }else if(element.target.className.includes("discard")){
            field = 'UNLIKED';
            if(element.target.className=='discard'){
              element.target.className='discardselected';
             
              value='true';
            }else{
              element.target.className='discard'
            }
           }
           dispatch({ loading: true,field: field,value:value,questionId:questionId}, "AxiomEvent", "selectRatingOrLikeDislike");
          
      }*/
          const responseContentDiv = tempDiv.querySelector(".response-content");
          if (responseContentDiv) {
            const root = ReactDOM.createRoot(responseContentDiv);

            // console.log(addAnsRefResp?.response?.response_type);
            root.render(
              <Response
                responseType={
                  addAnsRefResp?.response?.response_type != undefined
                    ? addAnsRefResp?.response?.response_type
                    : "html"
                }
                responseData={
                  addAnsRefResp?.response?.response_table != undefined
                    ? addAnsRefResp?.response?.response_table
                    : "Response Unavailable"
                }
                responseText={
                  addAnsRefResp?.response?.response_text != undefined
                    ? addAnsRefResp?.response?.response_text
                    : "Response Unavailable!!"
                }
                responseHTML={
                  addAnsRefResp?.response?.response_html != undefined
                    ? addAnsRefResp?.response?.response_html
                    : "Response Unavailable"
                }
              />,
            );
          }
        });
      }
    }
  }, [dispatch, props.userInfo.name, state.questionList, handleClick, handleResponseButtonClick]);

  const addQuestionToRef = useCallback(() => {
    if (ansContRef.current != null) {
      const tempDiv = document.createElement("div");
      (tempDiv.className = "abc"),
        (tempDiv.innerHTML = `
          <div class="question-text">
            <div class="text-content">${questionText}</div>
            <div class="question-text-logo-container" style="display: none;">
              <span class="question-text-logo" style="display: none;">${props.userInfo.name.charAt(0)}</span>
            </div>
          </div>
          <div class="answer-text for-logo-only">
            <div class="logo-wrapper">
              <div class="answer-text-logo"></div>
            </div>
            <div class="three-dot-loader"></div>
          </div>
          `);
      ansContRef.current.appendChild(tempDiv);
    }
  }, [questionText, props.userInfo]);

  const addAnswerToRef = useCallback(() => {
    if (ansContRef.current != null) {
      let addAnsRef;
      try {
        addAnsRef = JSON.parse(JSON.stringify(answer));
      } catch (ex) {
        addAnsRef = {
          response: { response_type: "text", response_text: "Error in response format." },
        };
      }
      const isTblDataExists =
        addAnsRef?.errResp !== true && addAnsRef?.response?.response_table?.data?.length > 0;
      console.log("questionId", state?.questionId);
      ansContRef.current.querySelectorAll(".answer-text.for-logo-only").forEach((d) => d.remove());

      const tempId = `temp-${Math.random().toString(36).substr(2, 9)}`;
      const elementId = state.questionId ? state.questionId : tempId;
      let starClass = "star";
      let likeClass = "like";
      let discardClass = "discard";
      const tempDiv = document.createElement("div");
      tempDiv.id = elementId;
      tempDiv.className = "answer-text";
      // tempDiv.innerHTML = `<div class="logo-wrapper">
      //       <div class="answer-text-logo"></div>
      //     </div>
      //     <div style="max-width:70%">
      //     <div class="text-content">
      //       <div class="ans-content">
      //         <div class="response-content"></div>

      //       </div>

      //     </div> <div class="rating-text">
      //     <div class="disclaimer" style="display: none;">AI-generated content may be incorrect testing.</div>
      //     <div className="button-container">
      //       <button className="custom-button">Table</button>
      //       <button className="custom-button">Chart</button>
      //       <button className="custom-button">SQL Script</button>
      //       <button className="custom-button">Thought Process</button>
      //     </div>
      //     <div id=${
      //       "star" + elementId
      //     } class=${starClass}></div><div class="borderdivider" style="display: none;"></div><div id=${
      //   "like" + elementId
      // } class=${likeClass} style="display: none;"></div><div id=${"discard" + elementId} class=${discardClass}></div>
      //     </div>
      //     </div> `;

      // <button id=${"table" + elementId} class=botResDetailBtnStyle01>Table</button>
      // <button id=${"chart" + elementId} class=botResDetailBtnStyle01>Chart</button>
      // <button id=${"sqlscript" + elementId} class=botResDetailBtnStyle01>SQL Script</button>

      let respBtnShow = "";
      if (isTblDataExists) {
        respBtnShow = `<button id=${"table" + elementId} class="botResDetailBtnStyle01">Table</button>
                <button id=${"chart" + elementId} class="botResDetailBtnStyle01">Chart</button>
                <button id=${"sqlscript" + elementId} class="botResDetailBtnStyle01">SQL Script</button>
        `;
      }

      const response_html_string = `
        <div class="logo-wrapper">
          <div class="answer-text-logo"></div>
        </div>
        <div style="max-width:70%">
          <div class="text-content">
            <div class="ans-content"> 
              <div class="response-content"></div>           
            </div>         
          </div>
          <div class="rating-text">
            <div class="botResDetailBtnContainer">
              <div class="left-btn-section">
               ${respBtnShow}
              </div>
              <div class="right-btn-section">
                <div id=${"star" + elementId} class=${starClass} style="display: none;"></div>
                <div class="borderdivider" style="display: none;"></div>
                <div id=${"like" + elementId} class=${likeClass}></div>
                <div id=${"discard" + elementId} class=${discardClass}></div>
              </div>
            </div>
          </div>
        </div> 
        `;

      // <button id=${"thoughtprocess" + elementId} class="botResDetailBtnStyle01">Thought Process</button>

      tempDiv.innerHTML = response_html_string;

      ansContRef.current.appendChild(tempDiv);
      const ratingStarElement = tempDiv.querySelector(`#star${elementId}`);
      const ratingLikeElement = tempDiv.querySelector(`#like${elementId}`);
      const ratingDislikeElement = tempDiv.querySelector(`#discard${elementId}`);
      const tableElement = tempDiv.querySelector(`#table${elementId}`);
      const chartElement = tempDiv.querySelector(`#chart${elementId}`);
      const sqlScriptElement = tempDiv.querySelector(`#sqlscript${elementId}`);
      // const thoughtProcessElement = tempDiv.querySelector(`#thoughtprocess${elementId}`);

      if (ratingStarElement) {
        ratingStarElement.addEventListener("click", (element) => handleClick(element, elementId));
        ratingLikeElement.addEventListener("click", (element) => handleClick(element, elementId));
        ratingDislikeElement.addEventListener("click", (element) =>
          handleClick(element, elementId),
        );
      }

      if (tableElement) {
        tableElement.addEventListener("click", (element) =>
          handleResponseButtonClick(element, elementId, "table", addAnsRef),
        );
        chartElement.addEventListener("click", (element) =>
          handleResponseButtonClick(element, elementId, "chart", addAnsRef),
        );
        sqlScriptElement.addEventListener("click", (element) =>
          handleResponseButtonClick(element, elementId, "sql", addAnsRef),
        );
        // thoughtProcessElement.addEventListener("click", (element) => handleResponseButtonClick(element, elementId, 'thought',addAnsRef));
      }

      // Define the handleClick function
      /* function handleClick(element) {
        let field=null,value='0';
          if(element.target.className.includes("star")){
            field = 'ISSTARRED';
            if(element.target.className=='rating star'){
              element.target.className='rating starselected';
             
              value='1';
            }else{
              element.target.className='rating star'
            }
            //dispatch({ loading: true,star: star,questionId:1234}, "AxiomEvent", "selectRatingOrLikeDislike");
          }else if(element.target.className.includes("like")){
             field = 'LIKED';
            if(element.target.className=='rating like'){
              element.target.className='likeselected';
             
              value='1';
            }else{
              element.target.className='like'
            }
          //  dispatch({ loading: true,like: like,questionId:1234}, "AxiomEvent", "selectRatingOrLikeDislike");
          }else if(element.target.className.includes("discard")){
            field = 'UNLIKED';
            if(element.target.className=='rating discard'){
              element.target.className='rating discardselected';
             
              value='1';
            }else{
              element.target.className='rating discard'
            }
           }
           dispatch({ loading: true,field: field,value:value,questionId:1234}, "AxiomEvent", "selectRatingOrLikeDislike");
          
      }*/
      const responseContentDiv = tempDiv.querySelector(".response-content");
      if (responseContentDiv) {
        const root = ReactDOM.createRoot(responseContentDiv);
        console.log("answer log", addAnsRef);
        console.log("addAnsRef.response_type", addAnsRef?.response?.response_type);
        console.log("addAnsRef.response_text", addAnsRef?.response?.response_text);
        console.log("addAnsRef.response_html", addAnsRef?.response?.response_html);

        root.render(
          <Response
            responseType={
              addAnsRef?.response?.response_type != undefined
                ? addAnsRef?.response?.response_type
                : "html"
            }
            responseData={
              addAnsRef?.response?.response_table != undefined
                ? addAnsRef?.response?.response_table
                : "Response Unavailable"
            }
            responseText={
              addAnsRef?.response?.response_text != undefined
                ? addAnsRef?.response?.response_text
                : "Response Unavailable"
            }
            responseHTML={
              addAnsRef?.response?.response_html != undefined
                ? addAnsRef?.response?.response_html
                : "Response Unavailable"
            }
          />,
        );
      }
    }
  }, [answer, handleClick, state.questionId, handleResponseButtonClick]);

  const scrollToBottom = () => {
    if (ansContRef.current) {
      ansContRef.current.scrollTop = ansContRef.current.scrollHeight;
    }
  };

  const handleLandingScreen = () => {
    setShowLandingScreen(!showLandingScreen);
  };

  const onSubmitQuestion = async () => {
    if (showLandingScreen) setShowLandingScreen(false);
    dispatch({ questionId: null });
    if (questionText) {
      if (!showLandingScreen) ansContRef.current.scrollTop = ansContRef.current.scrollHeight;
      addQuestionToRef();
      setQuestionText("");
      //props.setQueTextFromSidePanel("")
      let answer;

      console.log(questionText);
      // const url = "https://mocki.io/v1/e06986ef-3fab-4a1f-93dc-5cf580fc79ca";
      // const url = "https://mdlz-backend-361517524933.asia-south1.run.app/api/generate-query";
      const url =
        "https://saathi-dev-cloudrun-backend-189854251836.asia-south1.run.app/api/generate-query";
      const questionData = {
        questionText: questionText,
        userid: props?.userInfo?.email,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(questionData),
      };
      setDisableInput(true);
      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("mee:", data);
          if (data && !data.error) {
            // answer =  getAnswer(data);
            setAnswer(data);

            dispatch(
              { data: data, question: questionData.questionText },
              "AxiomEvent",
              "savefetchresponse",
            );
          }
          setDisableInput(false);
        })
        .catch((error) => {
          answer = getAnswer("error");
          setAnswer(answer);
          addAnswerToRef();
          setAnswer({});
          scrollToBottom();
          setDisableInput(false);
        });
    }
  };

  const getAnswer = (response) => {
    let resType;
    if (response == "error") {
      resType = "text";
      return {
        data: {},
        text: "I don’t think I got you there. I can try again if you can ask it differently.",
        type: resType,
        questionId: state.questionId ? state.questionId : null,
        errResp: true,
      };
    } else {
      resType = response.response.response_type;
      return {
        data: response.response.response_table,
        text: response.response.response_text,
        type: resType,
        questionId: state.questionId ? state.questionId : null,
      };
    }
    // const lowerCaseQuestion = questionText.toLowerCase();
    // if (lowerCaseQuestion.includes("bar")) {
    //   resType = "barChart";
    // } else if (lowerCaseQuestion.includes("grid")) {
    //   resType = "grid";
    // } else if (lowerCaseQuestion.includes("scatter")) {
    //   resType = "scatterChart";
    // } else {
    //   resType = "text";
    // }
  };

  const onInputKeyUp = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      onSubmitQuestion();
      e.target.value = "";
    }
  };

  const onInputChange = (e) => {
    setQuestionText(e.target.value);
  };

  useEffect(() => {
    if (Object.keys(answer).length && state.questionId) {
      console.log(state?.questionId);
      addAnswerToRef();
      setAnswer({});
      scrollToBottom();
    }
  }, [answer, addAnswerToRef, state.questionId]);

  const init_quest = [
    "Why has West BVT value dropped vs L3M? Show me state wise trends.",
    "Has BVT EBD dropped in these states vs L3M?",
    "What is the category wise growth In July-24?",
    "Show branch wise growth on Chocolate.",
  ];

  return (
    <div className="ChatWindow">
      <div className="main-body">
        <Dialog
          dialogText="View starred message only"
          toggleStarredMessage={toggleStarredMessage}
        />

        <div className="answers-container">
          {/* {!ansContRef?.current?.children?.length && !state.questionList?.length && ( */}
          {showLandingScreen && (
            <div className="backgroundTextContainer">
              {/* <span className="backgroundText">{props.welcomeText}</span> */}
              {/* {state?.questionList && <FaHistory className="historyButton" onClick={handleLandingScreen} />} */}
              <div className="container">
                <div className="row single-text-row">
                  <div className="centered-content first-two-header">
                    HI THERE, {props?.userInfo?.name}
                  </div>
                </div>
                <div className="row single-text-row">
                  <div className="centered-content first-two-header">
                    WHAT WOULD YOU LIKE TO KNOW?
                  </div>
                </div>
                <div className="row single-text-row">
                  <div className="centered-content">
                    Use one of the most common prompts below or use your own to begin
                  </div>
                </div>
                <div className="row four-divs">
                  <div
                    className="rectangle"
                    onClick={() => props.startScreenQuesClick(init_quest[0])}
                  >
                    {init_quest[0]}
                  </div>
                  <div
                    className="rectangle"
                    onClick={() => props.startScreenQuesClick(init_quest[1])}
                  >
                    {init_quest[1]}
                  </div>
                  <div
                    className="rectangle"
                    onClick={() => props.startScreenQuesClick(init_quest[2])}
                  >
                    {init_quest[2]}
                  </div>
                  <div
                    className="rectangle"
                    onClick={() => props.startScreenQuesClick(init_quest[3])}
                  >
                    {init_quest[3]}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="content-with-bottom-allign"
            ref={ansContRef}
            style={showLandingScreen ? { display: "none" } : {}}
          ></div>
        </div>
        <div className="bottom-text-container">
          <div className="input-question-container">
            <input
              type="text"
              value={questionText}
              onKeyUp={!disableInput ? onInputKeyUp : () => {}}
              placeholder="Type your question"
              onChange={onInputChange}
              autoFocus
              // disabled={disableInput}
            />
          </div>
          <div className="submit-icon-container" onClick={!disableInput ? onSubmitQuestion : () => {}}></div>
        </div>
      </div>
    </div>
  );
}

export default memo(ChatWindow2);
