import React from "react";

import AxiomModal from "../AxiomModal/AxiomModal";

import "./CookieConsent.scss";

const CookieModal = ({ isOpen, onAccept }) => {
  return (
    <AxiomModal isOpen={isOpen} fullScreen={true} className="cookie-modal">
      <div className="cookie-modal-content">
        <p className="cookie-modal-message">
          This webapp uses cookies to improve your experience. Cookies are small data files that are stored on your device when you visit a webapp. They help the site remember your preferences, identity and provide personalized content.
        </p>
        <p className="cookie-modal-message">
          Click "Accept" to consent to the use of cookies. If you choose not to accept cookies, please close the app, as it cannot work without cookies.
        </p>
        <button className="cookie-modal-button primary" onClick={onAccept}>
          Accept
        </button>
      </div>
    </AxiomModal>
  );
};

export default CookieModal;
