export default {
  ICON_USER: {
    viewBox: "0 0 11 15",
    data:
      '<path d="M0 14.9999H10.7143V12.3213C10.7132 11.3271 10.3177 10.3739 9.61468 9.67089C8.91167 8.96788 7.9585 8.57242 6.96429 8.57129H3.75C2.75579 8.57242 1.80262 8.96788 1.0996 9.67089C0.396587 10.3739 0.0011343 11.3271 0 12.3213V14.9999Z"/><path d="M1.60742 3.75C1.60742 4.49168 1.82736 5.2167 2.23941 5.83339C2.65147 6.45007 3.23714 6.93072 3.92236 7.21455C4.60758 7.49838 5.36158 7.57264 6.08901 7.42795C6.81644 7.28325 7.48463 6.9261 8.00907 6.40165C8.53352 5.8772 8.89067 5.20902 9.03537 4.48159C9.18006 3.75416 9.1058 3.00016 8.82197 2.31494C8.53814 1.62971 8.0575 1.04404 7.44081 0.631989C6.82413 0.219934 6.0991 0 5.35742 0C4.36286 0 3.40903 0.395088 2.70577 1.09835C2.00251 1.80161 1.60742 2.75544 1.60742 3.75Z"/>',
  },
  ICON_PASSWORD: {
    viewBox: "0 0 12 15",
    data:
      '<path d="M12 7.5C12 6.67275 11.3273 6 10.5 6H9.75V3.75C9.75 1.68225 8.06775 0 6 0C3.93225 0 2.25 1.68225 2.25 3.75V6H1.5C0.67275 6 0 6.67275 0 7.5V13.5C0 14.3273 0.67275 15 1.5 15H10.5C11.3273 15 12 14.3273 12 13.5V7.5ZM3.75 3.75C3.75 2.5095 4.7595 1.5 6 1.5C7.2405 1.5 8.25 2.5095 8.25 3.75V6H3.75V3.75Z"/>',
  },
};
