import React from 'react';
import Calendar from "./AxiomCal/Calendar.jsx";
import $ from "jquery";
import * as d3 from "d3-time-format";
import { FaArrowRight } from "react-icons/fa";

import { FaArrowLeft } from "react-icons/fa";

import { PiArrowRightThin } from "react-icons/pi";

import { PiArrowLeftThin } from "react-icons/pi";


const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default class AxiomCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date || new Date()
    };
    let that = this;
    this.onDateSelected = this.onDateSelected.bind(this);
    this.dateSelected = this.dateSelected.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.onTimeSelected = this.onTimeSelected.bind(this);
  }

  onDateSelected(date) {
    this.setState({
      date
    });
  }

  onTimeSelected(time) {
    this.setState({
      time
    });
  }

  timeParse(date, timeSting, ho) {
    var d = new Date(date),
      s = timeSting;
    if (this.props.hour24) {
      let parts = s.match(/(\d+)\.(\d+)/),
        hours = parseInt(parts[1], 10),
        minutes = parseInt(parts[2], 10);
      d.setHours(hours);
      d.setMinutes(minutes);
    } else {
      let parts = s.match(/(\d+)\.(\d+) (\w+)/),
        hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
        minutes = parseInt(parts[2], 10);
      d.setHours(hours);
      d.setMinutes(minutes);
    }
    return d;
  }

  monthYearParse(date) {
    let dateObj = new Date(date);
    var month = dateObj.getMonth() + 1; //months from 1-12
    var year = dateObj.getFullYear();
    return dateObj;
  }

  dateSelected() {
    if (this.props.timePicker && !this.props.monthYear) {
      let date = new Date(this.state.date);
      if (this.props.hour24) {
        var nowMilliseconds24 = this.timeParse(this.state.date, this.state.time || d3.timeFormat("%H.%M")(new Date("2015/01/02 00:00:00")), "24");
      } else {
        var nowMilliseconds = this.timeParse(this.state.date, this.state.time || d3.timeFormat("00.%M %p")(new Date("2015/01/02 00:00:00")), "12");
      }
      !this.props.hour24 ? date.setTime(nowMilliseconds) : date.setTime(nowMilliseconds24);
      if (this.props.format) {
        this.props.onDateSelected(d3.timeFormat(this.props.format)(date));
      } else {
        this.props.onDateSelected(new Date(date));
      }
    } else if (this.props.monthYear) {
      let monthYear = this.monthYearParse(this.state.date);
      this.props.onDateSelected(monthYear);
    } else {
      if (this.props.format) {
        this.props.onDateSelected(d3.timeFormat(this.props.format)(new Date(this.state.date)));
      } else {
        this.props.onDateSelected(new Date(this.state.date));
      }
    }
    $(".AxiomCalendarWrapper").css("visibility", "hidden");
    $("body").removeClass("noscroll");
    this.props.onCalendarClosed();
  }

  closeCalendar() {
    this.props.onCalendarClosed();
    $("body").removeClass("noscroll");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.date) {
      this.setState({
        date: nextProps.date
      });
    }
  }

  getAMPM = () => {
    var date = new Date(this.state.date);
    var hours = date.getHours();
    var ampm = hours >= 12 ? "PM" : "AM";
    return "AM";
  };

  getHour = () => {
    var date = new Date(this.state.date);
    var hours = date.getHours();
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    return 0;
  };

  getMin = () => {
    var date = new Date("12-05-06 00:00:00");
    return 0;
  };

  render() {
    let calendarStyle = {};

    if (this.props.calendarState === true) {
      calendarStyle.visibility = "visible";
      $("body").addClass("noscroll");
    } else {
      calendarStyle.visibility = "hidden";
      $("body").removeClass("noscroll");
    }

    return (
      <div style={calendarStyle} className="AxiomCalendarWrapper">
        <div className="AxiomCalendarMain">
          <Calendar
            maxDate= {this.props.maxDate}
            hour24={this.props.hour24}
            onClearClick={() => this.props.onClearClick ? this.props.onClearClick() : null }
            defaultHour={() => this.getHour()}
            defaultMinute={() => this.getMin()}
            defaultAMPM={() => this.getAMPM()}
            disabledMonths={this.props.disabledMonths}
            disabledDates={this.props.disabledDates}
            monthYear={this.props.monthYear}
            onClickMonth={this.onDateSelected}
            timePicker={this.props.timePicker}
            timePickerOnly={this.props.timePickerOnly}
            nextLabel={<FaArrowRight style={{ color: "#FFF" }} size={24} />}
            next2Label={<PiArrowRightThin style={{ color: "#FFF" }} size={28} />}
            prevLabel={<FaArrowLeft style={{ color: "#FFF" }} size={24} />}
            prev2Label={<PiArrowLeftThin style={{ color: "#FFF" }} size={28} />}
            onClickDay={this.onDateSelected}
            onTimeSelected={this.onTimeSelected}
            dateSelected={this.dateSelected}
            closeCalendar={this.closeCalendar}
            value={this.state.date} />
        </div>
      </div>
    );
  }
}