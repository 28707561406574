import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { IoTimerOutline } from "react-icons/io5";
import { IoMenu } from "react-icons/io5";
import { IoMdMore } from "react-icons/io";
import { IoCalendar } from "react-icons/io5";
import { IoFunnel } from "react-icons/io5";
import { IoNotifications } from "react-icons/io5";
import logo from "./monitor.png";
import parse from "html-react-parser";
import Icon from "../Icons/Icon";

import "./AxiomAppBar.scss";
import { AxiomAppContext } from "__AxiomComponents/AxiomLayout/AxiomAppContext";

export default class AxiomAppBar extends React.Component {
  static contextType = AxiomAppContext;
  constructor(props) {
    super(props);
    this.state = {
      isRightMenuOpen: false,
    };
  }

  onHistory = () => {
    AxiomAPIs.startHistoryMode();
  };

  toggleMenu = () => {
    this.setState({ isRightMenuOpen: !this.state.isRightMenuOpen });
  };

  render() {
    const leftPanelIcons = {
      label: "Home",
      icon: "ICON_HOME",
      url: "/apps/NestleHome",
    };

    let styles = {
      axiomIcons: {
        color: "black",
      },
      axiomSize: {
        iconSize: 25,
      },
    };

    return (
      <div className="axiom-navbar" style={{ background: "#472380" }}>
        <div className="axiom-navbar-left">
          <button
            onClick={this.props.onMenuClick}
            className="axiom-navbar-action axiom-navbar-menu"
          >
            {/* {this.props.showPrevious ? (
              <IoArrowBack size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
            ) : this.props.isCustomAppBar ? (
              <Icon icon={"ICON_HOME_HEADER"} className="landing-app-icon logout-icon" />
            ) : (
              <IoMenu size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
            )} */}
            <svg
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0.5H2C0.9 0.5 0 1.4 0 2.5V15.5C0 16.6 0.9 17.5 2 17.5H20C21.1 17.5 22 16.6 22 15.5V2.5C22 1.4 21.1 0.5 20 0.5ZM2 15.5V2.5H10V15.5H2ZM20 15.5H12V2.5H20V15.5ZM13 6H19V7.5H13V6ZM13 8.5H19V10H13V8.5ZM13 11H19V12.5H13V11Z"
                fill="white"
              />
            </svg>
          </button>
          <img
            className="axiom-navbar-logo"
            src={
              this.props.appLogo && this.props.appLogo != ""
                ? this.props.appLogo
                : this.props.projectLogo && this.props.projectLogo != ""
                  ? this.props.projectLogo
                  : logo
            }
            style={{ height: this.props.appLogoHeight }}
            alt="Logo"
          />
        </div>
        <div className="axiom-navbar-title">
          {this.props.title && <span>{parse(this.props.title)}</span>}
        </div>
        {this.props.userInfo && (
          <div className="home-user">
            {this.props.userInfo.profilePhoto ? (
              <div>
                <img className="home-user-avatar" src={this.props.userInfo.profilePhoto} />
              </div>
            ) : (
              <div>
                <span className="home-user-avatar">{this.props.userInfo.name.charAt(0)}</span>
              </div>
            )}
            <div className="home-user-info">
              <div className="home-user-name">
                <div>
                  <a>Hello</a>
                  <a>&nbsp;{this.props.userInfo ? this.props.userInfo.name : ""},</a>
                </div>
                <div>
                  <a>{this.props.userInfo ? this.props.userInfo.userRole : ""}</a>
                </div>
              </div>
              <div className="logout-pass-wrapper">
                <a
                  onClick={() => {
                    this.props.handleLogout?.();
                    this.props.logout();
                  }}
                  tabIndex="0"
                  className="home-user-logout"
                >
                  <Icon icon={"ICON_LOGOUT"} className="landing-app-icon logout-icon" />
                  {/* <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00554 12.4068C9.16111 12.5673 9.35546 12.6475 9.54998 12.6475C9.74448 12.6475 9.93883 12.5673 10.0944 12.4068L12.9334 9.47827C13.2445 9.15731 13.2445 8.65585 12.9334 8.33489L10.0944 5.42651C9.78328 5.10555 9.29717 5.10555 8.98602 5.42651C8.67488 5.74747 8.67488 6.24893 8.98602 6.56989L10.5027 8.13441H5.7777C5.34993 8.13441 5 8.49539 5 8.93666C5 9.37794 5.34993 9.73891 5.7777 9.73891H10.5027L8.98602 11.3034C8.69441 11.5844 8.69441 12.1058 9.00555 12.4068H9.00554Z"
                      fill="white"
                    />
                    <path
                      d="M13.2223 6.30903C13.6501 6.30903 14 5.94806 14 5.50678V3.18002C14 1.57534 12.7556 0.291626 11.2 0.291626H2.80002C1.24444 0.291626 0 1.57534 0 3.18002V14.6733C0 16.2579 1.24444 17.5416 2.80002 17.5416H11.2194C12.7555 17.5416 13.9999 16.2579 13.9999 14.6532V12.3265C13.9999 11.8852 13.65 11.5242 13.2222 11.5242C12.7944 11.5242 12.4445 11.8852 12.4445 12.3265V14.6532C12.4445 15.3553 11.8807 15.9369 11.2 15.9369H2.80009C2.11947 15.9369 1.55565 15.3553 1.55565 14.6532V3.18005C1.55565 2.47795 2.11947 1.89634 2.80009 1.89634H11.2195C11.9001 1.89634 12.4639 2.47795 12.4639 3.18005V5.50682C12.4444 5.94809 12.7945 6.30907 13.2223 6.30907L13.2223 6.30903Z"
                      fill="white"
                    />
                  </svg> */}
                </a>
              </div>
            </div>
          </div>
        )}
        {(this.props.filterIcon ||
          this.props.calendarIcon ||
          this.props.historyIcon ||
          this.props.notificationIcon) && (
          <button onClick={this.toggleMenu} className="axiom-navbar-action axiom-navbar-toggle">
            <IoMdMore size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
          </button>
        )}
        <div className={"axiom-navbar-right " + (this.state.isRightMenuOpen ? "is-open" : "")}>
          {this.props.filterIcon && (
            <button onClick={this.props.openFilterPanel} className="axiom-navbar-action">
              <IoFunnel size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              <span className="axiom-navbar-label">Filters</span>
            </button>
          )}
          {this.props.calendarIcon && (
            <button onClick={this.props.onCalendar} className="axiom-navbar-action">
              <IoCalendar size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              <span className="axiom-navbar-label">Select Date</span>
            </button>
          )}
          {this.props.historyIcon && (
            <button onClick={this.onHistory} className="axiom-navbar-action">
              <IoTimerOutline size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              <span className="axiom-navbar-label">History Mode</span>
            </button>
          )}
          {this.props.notificationIcon && (
            <button className="axiom-navbar-action">
              <IoNotifications size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              {this.props.notificationCount && this.props.notificationCount > 0 ? (
                <span className="axiom-navbar-badge">{this.props.notificationCount}</span>
              ) : null}
              <span className="axiom-navbar-label">Notifications</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}
