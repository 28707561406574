import React, { useRef } from "react";
import ReactECharts from "echarts-for-react";
import html2canvas from "html2canvas";
import {
  botLineChartToolTip,
  capitalizeFirstLetter,
  textToLowerCase,
  checkValuesInJsonArray,
  checkArrayLength,
} from "./chatbot_utils.js";
import "./BotResponseLineChart.scss";
import "./BotChartToolTip.scss";

const BotResponseLineChart = ({ responseObject }) => {
  const chartRef = useRef(null);

  const { resp: responseData } = responseObject;
  const { title_html, data: rows, headers } = responseData?.response?.response_table;
  const { xAxis, yAxis, labels } = responseData?.response?.response_chart;

  // Extract X-axis labels from headers excluding the first column
  const xAxisLabels = xAxis;

  let legendField = labels;

  if (checkArrayLength(checkValuesInJsonArray([legendField], headers, "displayName")) == 0) {
    legendField = "Series 1"
  }

  // Check if every element in xAxis exists in headers
  if (checkArrayLength(checkValuesInJsonArray(xAxisLabels, headers, "displayName")) == 0) {
    return <>Chart Unavailable</>;
  }

  //Function to get mapping name from display name
  const getHeaderMappingName = (valueArray) => {
    const mapping_name = headers
      .filter((header) => valueArray.includes(header.displayName))
      .map((header) => header.mapping);
    return mapping_name;
  };

  const lableColMapName = (legendField && getHeaderMappingName([legendField])) || "";

  const xAxisColMapName = (xAxis && xAxis.map((item) => getHeaderMappingName([item]))) || [];

  const filteredRows = rows.filter((item) => textToLowerCase(item[lableColMapName]) !== "total");

  // Function to generate the series data dynamically
  const seriesData = () => {
    return filteredRows.map((item) => ({
      name: capitalizeFirstLetter(item[lableColMapName]),
      type: "line",
      data: xAxisColMapName.map((col) => item[col]),
      smooth: true,
      symbol: "none",
      lineStyle: {
        width: 2,
      },
    }));
  };

  const legends = filteredRows.map((item) => capitalizeFirstLetter(item[lableColMapName]));

  // Tooltip Formatter
  const tooltipFormatter = (params) => {
    return botLineChartToolTip(params, legendField, yAxis);
  };

  const option = {
    title: {
      text: "",
      left: "left",
    },
    tooltip: {
      trigger: "axis",
      formatter: tooltipFormatter,
    },
    legend: {
      data: legends,
      icon: "rect",
      top: "10%",
      right: "5%",
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        fontSize: 8, // Change font size of the legend
      },
      // bottom: 20, // Place the legend at the bottom
      // left: 'center',
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisLabels,
      axisLabel: {
        fontSize: 8, // Change font size of the X-axis labels
      },
      boundaryGap: true,
      axisTick: {
        show: false, // Hide ticks on X-axis
      },
      axisLine: {
        lineStyle: {
          // color: "#000", // Set the x-axis line color
        },
      },
    },
    yAxis: {
      type: "value",
      name: yAxis,
      axisLabel: {
        fontSize: 8, // Change font size of the X-axis labels
      },
      nameTextStyle: {
        fontSize: 12, // Font size for the Y-axis name
      },
      nameLocation: "middle", // Align Y-axis name to the middle of the axis
      nameGap: 30,
      axisLine: {
        show: true,
        lineStyle: {
          // color: "#000", // Set the y-axis line color
        },
      },
      splitLine: {
        lineStyle: {
          type: "dotted", // Set horizontal grid lines to dotted
          color: "lightgrey", // Color of the dotted grid lines
          width: 1,
          opacity: 0.5,
        },
      },
    },
    series: seriesData(),
  };

  // Download chart as an image
  const downloadChart = () => {
    const chartElement = chartRef.current.getEchartsInstance().getDom();
    html2canvas(chartElement).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "chart.png";
      link.click();
    });
  };

  return (
    <div className="bot-response-chart-container">
      <div className="chart-header">
        <h3 className="chart-title">{title_html}</h3>
        <button className="download-btn" onClick={downloadChart}>
          Download
        </button>
      </div>
      <p className="chart-subtitle" style={{ display: "none" }}>
        Value shown as XXXX
      </p>
      <ReactECharts ref={chartRef} option={option} style={{ height: "300px" }} />
    </div>
  );
};

export default BotResponseLineChart;
