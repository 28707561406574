import AxiomLayout from "__AxiomComponents/AxiomLayout/AxiomLayout.jsx";
import Chatbot from "../components/Chatbot/Chatbot.js"


function Container(props) {
  return (
    <AxiomLayout
      axiomTheme="axiomWhite"
      appName={props.appName}
      title={props.appName}
      appID={props.appID}
      componentID="0"
      authorized={props.authorized}
      layoutName="AxiomApp"
    >      
      <Chatbot appName={props.appName} appID={props.appID} componentID="0" />
    </AxiomLayout>
  );
}

export default Container;
