import { useState, useEffect, useLayoutEffect, useCallback, useRef, useContext } from "react";
import { AxiomAppContext } from "__AxiomComponents/AxiomLayout/AxiomAppContext";

// Use this hook to register your component as Axiom component
// This hook will provide you with a state object and a function to update the state
function useAxiomState(initialState = {}, appName, appID, componentName, componentID) {
  if (typeof initialState !== "object") {
    console.error(
      `Invalid initial axiom state provided for ${componentName}. initial state for Axiom component must be an object`
    );
    throw Error("INVALID_INITIAL_AXIOM_STATE");
  }

  if (appName == null || appID == null || componentName == null || componentID == null) {
    console.error(
      `appName: "${appName}", appID: "${appID}", componentName: "${componentName}", componentID: "${componentID}" are mandatory information`
    );
    throw Error("INVALID_AXIOM_STATE_REGISTRATION");
  }

  const [state, setState] = useState(initialState);
  const stateRef = useRef(initialState);

  useLayoutEffect(() => {
    // Register with Axiom on component mount
    AxiomAPIs.registerStateChange(appName, appID, {
      state: {...state, componentName},
      props: { componentID },
      setState: (newState, cb) => {
        if (typeof newState !== "object") {
          console.error(
            `Invalid state provided for ${componentName}. state for Axiom component must be an object`
          );
        } else {
          setState((prevState) => ({ ...prevState, ...newState }));
          if (cb && typeof cb == "function") {
            setTimeout(() => {
              cb();
            }, 0);
          }
        }
      },
    });
    return () => {
      // Remove Axiom Registration on unmount
      AxiomAPIs.registeredApps[appName].appIDs[appID].registeredComponents[
        `${componentName}${componentID}`
      ].componentWillUnmount();
    };
  }, [appName, appID, componentName, componentID,state]);

  stateRef.current = state;

  const dispatch = useCallback(
    (data, type = "AxiomEventInfo", name = "setState") => {
      if (typeof data === "function") {
        // eslint-disable-next-line no-param-reassign
        data = data(stateRef.current);
      }
      // If event is local and no data is provided skip it
      if (data == null && type === "AxiomEventInfo") {
        return;
      } else {
        const event = new CustomEvent(type, {
          detail: {
            appName: appName,
            appID: appID,
            componentID: componentID,
            componentName: componentName,
            eventName: name,
            data: data,
          },
        });
        AxiomAPIs.dispatchEvent(event);
      }
    },
    [appName, appID, componentName, componentID]
  );

  return [state, dispatch];
}

// Use this hook to dispatch event to another Axiom Component
// This hook will provide you with a function to dispatch AxiomEvents for any Axiom component
function useAxiomEvent(appName, appID, componentName, componentID) {
  const dispatchEvent = useCallback(
    (data, type = "AxiomEventInfo", name = "setState") => {
      const event = new CustomEvent(type, {
        detail: {
          appName: appName,
          appID: appID,
          componentID: componentID,
          componentName: componentName,
          eventName: name,
          data: data,
        },
      });
      AxiomAPIs.dispatchEvent(event);
    },
    [appName, appID, componentName, componentID]
  );
  return dispatchEvent;
}

// Use this hook to fetch the app context like theme, appSettings
function useAxiomAppContext() {
  return useContext(AxiomAppContext);
}

function usePivotData(pivotConfig, KPIWiseFilters, dispatch, subscribeID) {
  if (pivotConfig.some((config) => typeof config !== "object") && !Array.isArray(pivotConfig)) {
    console.error(`Invalid pivotConfig. Pivot Config must be an array of objects`);
    throw Error("INVALID_PIVOT_CONFIG");
  }

  useEffect(() => {
    dispatch({ loading: true });
    if (pivotConfig && pivotConfig.length) {
      AxiomAPIs.getPivotReportData(
        pivotConfig,
        KPIWiseFilters,
        (result) => {
          if (result) {
            dispatch(
              {
                pivotData: result,
                loading: false,
              },
              "AxiomEventInfo",
              "getPivotData"
            );
          } else {
            console.error("Error in getting Pivot Data");
            throw Error("Error in getting Pivot Data");
          }
        },
        null,
        null,
        subscribeID
      );
    }
  }, [dispatch, pivotConfig, KPIWiseFilters, subscribeID]);
}

function usePrevious(value, initialValue) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function useEffectDebugger(effectHook, dependencies, dependencyNames = []) {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log("[use-effect-debugger] ", changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
}

export { useAxiomState, useAxiomEvent, useAxiomAppContext, usePivotData, useEffectDebugger };
