import React, { memo, useEffect, useMemo, useState } from "react";
import "./Dialog.scss";
import { useAxiomState } from "__AxiomComponents/AxiomHooks/AxiomHooks";
import AxiomLoader from "../../../AxiomComponents/AxiomLoader/AxiomLoader";
import AxiomSelect from "__AxiomComponents/AxiomSelect/AxiomSelect";
import AxiomToogle from "__AxiomComponents/AxiomToogle/AxiomToogle";

function Dialog(props) {
  // const [state, dispatch] = useAxiomState(
  //   {
  //     loading: false,
  //   },
  //   props.appName,
  //   props.appID,
  //   "Dialog",
  //   props.componentID
  // );

  // useEffect(() => {
  //   dispatch({ loading: true }, "AxiomEvent", "mount");
  // }, [dispatch]);

  

  return (
    <div className="Dialog">
      {/* {state.loading && <AxiomLoader background={"rgba(255, 255, 255, 0.4)"} block=".Dialog" />} */}
      {/* uncomment row 29 to 34 to show view star message toggle button */}
      {/* <div className="toggle-container">
      <div className="dialog-text-box">{props.dialogText}</div>
      <div className="absolute-toggler">
        <AxiomToogle onClick={props.toggleStarredMessage}/>
      </div>
      </div> */}
    </div>
  );
}

export default memo(Dialog);
