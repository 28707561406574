import React, { Component } from "react";

class AxiomErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isErrored: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error.message);
    console.error(error);
    console.error(errorInfo);
    this.setState({ isErrored: true });
  }

  render() {
    if (this.state.isErrored) {
      return (
        <div
          style={{
            display: "flex",
            background: "#ccc",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg width="50" height="50" viewBox="0 0 32 32">
            <path d="M10.578 15.21h-0.898v-1.364h0.898c0.376 0 0.682 0.306 0.682 0.682s-0.306 0.682-0.682 0.682zM16.018 15.21h-0.898v-1.364h0.898c0.376 0 0.682 0.306 0.682 0.682s-0.306 0.682-0.682 0.682zM26 15.21v-1.364h0.898c0.376 0 0.682 0.306 0.682 0.682s-0.306 0.682-0.682 0.682h-0.898zM29.737 9.794h-27.473c-1.25 0-2.263 1.013-2.263 2.263v7.885c0 1.25 1.013 2.263 2.263 2.263h27.473c1.25 0 2.263-1.013 2.263-2.263v-7.885c-0-1.25-1.014-2.263-2.264-2.263zM6.359 15.21c0.397 0 0.718 0.322 0.718 0.718s-0.322 0.718-0.718 0.718h-1.939v1.364h1.939c0.397 0 0.718 0.322 0.718 0.718s-0.322 0.718-0.718 0.718h-2.657c-0.397 0-0.718-0.322-0.718-0.718v-5.602c0-0.397 0.322-0.718 0.718-0.718h2.657c0.397 0 0.718 0.322 0.718 0.718s-0.322 0.718-0.718 0.718h-1.939v1.364h1.939zM12.521 18.403c0.26 0.3 0.227 0.753-0.073 1.013-0.136 0.118-0.303 0.175-0.47 0.175-0.201 0-0.401-0.084-0.543-0.248l-1.755-2.027v1.413c0 0.397-0.322 0.718-0.718 0.718s-0.718-0.322-0.718-0.718v-5.602c0-0.397 0.322-0.718 0.718-0.718h1.616c1.168 0 2.119 0.95 2.119 2.119 0 1.035-0.745 1.898-1.727 2.082l1.552 1.792zM17.961 18.403c0.26 0.3 0.227 0.753-0.073 1.013-0.136 0.118-0.303 0.175-0.47 0.175-0.201 0-0.401-0.084-0.543-0.248l-1.755-2.027v1.413c0 0.397-0.322 0.718-0.718 0.718s-0.718-0.322-0.718-0.718v-5.602c0-0.397 0.322-0.718 0.718-0.718h1.616c1.168 0 2.119 0.95 2.119 2.119 0 1.035-0.745 1.898-1.727 2.082l1.552 1.792zM23.576 17.364c0 1.228-0.999 2.226-2.226 2.226s-2.226-0.999-2.226-2.226v-2.729c0-1.228 0.999-2.226 2.226-2.226s2.226 0.999 2.226 2.226v2.729zM28.841 18.403c0.26 0.3 0.227 0.753-0.073 1.013-0.136 0.118-0.303 0.175-0.47 0.175-0.201 0-0.401-0.084-0.543-0.248l-1.755-2.027v1.413c0 0.397-0.322 0.718-0.718 0.718s-0.718-0.322-0.718-0.718v-5.602c0-0.397 0.322-0.718 0.718-0.718h1.616c1.168 0 2.119 0.95 2.119 2.119 0 1.035-0.745 1.898-1.727 2.082l1.552 1.792zM22.14 17.364c0 0.436-0.354 0.79-0.79 0.79s-0.79-0.354-0.79-0.79v-2.729c0-0.436 0.354-0.79 0.79-0.79s0.79 0.354 0.79 0.79v2.729z" />
          </svg>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default AxiomErrorBoundry;
