import { useLayoutEffect, useRef, useState, useCallback } from "react";

function useWidthHeightCalculator() {
  const targetRef = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(() => {
    if (targetRef.current) {
      setWidth(Math.floor(targetRef.current.offsetWidth));
      setHeight(Math.floor(targetRef.current.offsetHeight));
    }
  }, []);

  useLayoutEffect(() => {
    //Adding resize listener to update the height and widht if the window size changes
    const element = targetRef.current;
    if (element) {
      handleResize();
    } else {
      return;
    }

    if (typeof ResizeObserver === "function") {
      let resizeObserver = new ResizeObserver(function () {
        handleResize();
      });
      resizeObserver.observe(element);

      return function () {
        resizeObserver.disconnect(element);
        resizeObserver = null;
      };
    } else {
      window.addEventListener("resize", handleResize);

      return function () {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [handleResize]);

  return [targetRef, width, height];
}

export { useWidthHeightCalculator };
