const formatNumberLocal = (number) => {
  return number.toLocaleString();
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const isNumeric = (value) => {
  return !isNaN(value) && !isNaN(parseFloat(value));
};

const formatNumberIND = (num) => {
  if (isNaN(num)) return "";

  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
};

const textToLowerCase = (textVal) => {
  let chart_type_name = "";

  if (typeof textVal === "string") {
    chart_type_name = textVal.toLowerCase();
  }

  return chart_type_name;
};

const botLineChartToolTip = (params, labelname, value) => {
  const dateLabel = params[0].axisValueLabel;

  let totalSumValue = 0;

  params.forEach((element) => {
    if (isNumeric(element.value)) {
      totalSumValue += element.value;
    }
  });

  const tblTotalRow = `
  <div class="table-row">
    <div class="table-cell">
      <span class="color-box" style="background-color: #fff;"></span>
      <span class="first-text-upper">Total</span>
    </div>
    <div class="table-cell table-cell-row-numeric"><span class="first-text-upper">${formatNumberIND(totalSumValue)}</span></div>
  </div>`;

  const tblRows = params.map(
    (item, index) =>
      `<div class="table-row" key=${index}>
          <div class="table-cell">
            <span class="color-box" style="background-color: ${item.color};"></span>
            <span class="first-text-upper">${item.seriesName}</span>
          </div>
          <div class="table-cell table-cell-row-numeric">${formatNumberIND(item.value)}</div>
        </div>`,
  );

  let tooltipContent = `
      <div class="charttooltip-table-container">
        <div class="table-row table-title">
          <div class="table-cell"><span class="first-text-upper">${dateLabel}</span></div>
        </div>
        <div class="table-row table-title table-header">
          <div class="table-cell">
            <span class="color-box" style="background-color: none;"></span>
            <span class="first-text-upper">${labelname}</span>
          </div>
          <div class="table-cell"><span class="first-text-upper">${value}</span></div>
        </div>
        ${tblRows.join("")}
        ${tblTotalRow}
      </div>`;

  return `<div>${tooltipContent}</div>`;
};

// Check if each value in valuesArray exists in jsonArray by the specified key ('key')
const checkValuesInJsonArray = (valueArr, jsonObj, jsonKey) => {
  return valueArr.filter((value) => jsonObj.some((obj) => textToLowerCase(obj[jsonKey]) === textToLowerCase(value)));
};

// Check if each value in valuesArray exists in jsonArray by the specified key ('key')
const isXaxisAllValuesAvailable = (valueArr, jsonObj, jsonKey) => {
  try {
    const valAvail = checkValuesInJsonArray(valueArr, jsonObj, jsonKey);
    return valAvail.len() == valueArr.len();
  } catch (error) {
    return false;
  }
};

// Function to check if the value is an array and get its length
const checkArrayLength = (value) => {
  if (Array.isArray(value)) {
    return value.length;
  } else {
    return 0;
  }
};

export {
  botLineChartToolTip,
  capitalizeFirstLetter,
  isNumeric,
  formatNumberLocal,
  formatNumberIND,
  textToLowerCase,
  checkValuesInJsonArray,
  isXaxisAllValuesAvailable,
  checkArrayLength
};
