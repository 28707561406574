import React, { memo, useEffect, useMemo, useState } from "react";
import "./Sidebar.scss";
import { useAxiomState } from "__AxiomComponents/AxiomHooks/AxiomHooks";
import AxiomLoader from "../../../AxiomComponents/AxiomLoader/AxiomLoader";
import AxiomSelect from "__AxiomComponents/AxiomSelect/AxiomSelect";
import Questions from "./Questions";

function Sidebar(props) {
  const [state, dispatch] = useAxiomState(
    {
      loading: false,
      pastQuestion: [],
      pastQuestionLoaded: false,
    },
    props.appName,
    props.appID,
    "Sidebar",
    props.componentID,
  );

  useEffect(() => {
    if (!(state.pastQuestion && state.pastQuestion.length) && state.pastQuestionLoaded == false) {
      dispatch({ pastQuestionLoaded: true });
      dispatch(
        {
          limit: props.pastPromptLimit,
        },
        "AxiomEvent",
        "mount",
      );
    }
  }, [state.pastQuestion, props.pastPromptLimit, state.pastQuestionLoaded, dispatch]);

  const mainQuestions = [
    "Q1",
    "Q2",
    "Q3",
    "Q4",
  ];
  const subQuestions = ["SQ1"];

  const mainTitle = "Suggested Questions";
  const subTitle = "Last 7 Days";

  // useEffect(() => {
  //   dispatch({ loading: true }, "AxiomEvent", "mount");
  // }, [dispatch]);

  return (
    <div>
      {props?.isSidebarOpen && (
        <div className="sidebar-backdrop" onClick={props.toggleDrawer}>
          <div className="Sidebar">
            {state.loading && (
              <AxiomLoader background={"rgba(255, 255, 255, 0.4)"} block=".Sidebar" />
            )}
            {/* <div style={{height : '50%'}} >
      <Questions
          questionData={mainQuestions}
          onClick={props.onClick}
          title={mainTitle}
        />
      </div> */}
            <div className="title-icon-container">
              <div className="title">PAST PROMPTS</div>
              <div className="icon" onClick={props.toggleDrawer}>
                <svg
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 0.5H2C0.9 0.5 0 1.4 0 2.5V15.5C0 16.6 0.9 17.5 2 17.5H20C21.1 17.5 22 16.6 22 15.5V2.5C22 1.4 21.1 0.5 20 0.5ZM20 15.5H12V2.5H20V15.5ZM13 6H19V7.5H13V6ZM13 8.5H19V10H13V8.5ZM13 11H19V12.5H16H13V11Z"
                    fill="#472380"
                  />
                </svg>
              </div>
            </div>
            <div style={{ height: "80%" }}>
              <Questions
                questionData={
                  (state.pastQuestion &&
                    state.pastQuestion.length &&
                    state.pastQuestion.map((que) => que.QUESTIONTEXT)) ||
                  []
                }
                // questionData={mainQuestions}
                onClick={props.onClick}
                title={subTitle}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Sidebar);
