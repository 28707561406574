import ReactDOM from "react-dom/client";
import Container from "./Container";

let timer = null;
function checkAxiomAPIs() {
  if (typeof AxiomAPIs != "undefined") {
    clearInterval(timer);
    AxiomAPIs.registerApp("Chatbot", renderReact, false, "React", [
      "Sidebar",
      "Dialog",
      "TextWrapper",
      "ChatBody",
      "Grid"
    ]);
  }
}

function renderReact(element, props) {
  const appRoot = ReactDOM.createRoot(element);
  appRoot.render(
    <Container
      appName={props.appName}
      appID={props.appID}
      backgroundColor={props.backgroundColor}
      client={props.client}
    />
  );
}

timer = setInterval(checkAxiomAPIs, 100);
