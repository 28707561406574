import React from "react";
import $ from "jquery";
import AxiomLoginNew from "../AxiomLogin/AxiomLoginNew.jsx";
import AxiomSnackBar from "../AxiomSnackBar/AxiomSnackBar.jsx";
import "./AxiomLayoutComponent.scss";
import AxiomAppBar from "../AxiomUI/AxiomAppBar/AxiomAppBar.jsx";
import AxiomDrawer from "../AxiomUI/AxiomDrawer/AxiomDrawer.jsx";
import AxiomCalendar from "../AxiomUI/AxiomCalendar/AxiomCalendar.jsx";
import AxiomErrorBoundry from "../AxiomErrorBoundry/AxiomErrorBoundry";
import "../../../styles/app.css";
import "./bootstrap/bootstrap-4.1.3-dist/css/bootstrap-grid.css";
import { AxiomAppContext } from "./AxiomAppContext";
import AxiomLanguageSelection from "./AxiomLanguageSelection.js";
import AxiomLoader from "__AxiomComponents/AxiomUI/AxiomLoader/AxiomLoader.jsx";
import Utils from "__AxiomComponents/Utils";
import CookieConsentModal from "./CookieConsent.js";

const theme = {
  axiomWhite: {
    background: "#F9F9F9",
    appbarColor: "#FFFFFF",
    iconsColor: "#000000",
    textColor: "#000000",
    textColorSecondary: "#7c7b7d",
    cardColor: "#FFFFFF",
    borderColor: "#f1f1f1",
  },
  axiomLight: {
    background: "#f8f8f8",
    appbarColor: "#0c6471",
    iconsColor: "#FFFFFF",
    textColor: "#000000",
    textColorSecondary: "#7c7b7d",
    cardColor: "#FFFFFF",
    borderColor: "#f1f1f1",
  },
  axiomDark: {
    background: "#000000",
    appbarColor: "#000000",
    iconsColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textColorSecondary: "#8c8c8c",
    cardColor: "#232323",
    borderColor: "#f1f1f1",
  },
  axiomRedTheme: {
    background: "#f8f8f8",
    appbarColor: "#F2142B",
    iconsColor: "#FFFFFF",
    textColor: "#000000",
    textColorSecondary: "#7c7b7d",
    cardColor: "#FFFFFF",
    borderColor: "#f1f1f1",
  },
};

export default class AxiomLayoutComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = $.extend(
      true,
      {
        componentName: "AxiomLayout",
        appList: null,
        drawerState: false,
        calendarState: false,
        mobileMenuState: false,
        userName: "",
        userEmailID: "",
        noLogin: this.props.noLogin || false,
        logged: false,
        resizeCount: 0,
        isLogoutDisabled: false,
        showPrevious: false,
        date: new Date(),
        cookiesAccepted: localStorage.getItem("cookiesAccepted") === "true",
      },
      props
    );

    AxiomAPIs.registerStateChange(this.props.appName, this.props.appID, this);

    this.drawerToggle = this.drawerToggle.bind(this);
    this.onDrawerStateClosed = this.onDrawerStateClosed.bind(this);
    this.redirectToApp = this.redirectToApp.bind(this);
    this.logout = this.logout.bind(this);
    this.menuToggle = this.menuToggle.bind(this);
    this.onMobileMenuClosed = this.onMobileMenuClosed.bind(this);
    this.openAxiomCalendar = this.openAxiomCalendar.bind(this);
    this.onCalendarClosed = this.onCalendarClosed.bind(this);
    this.onDateSelected = this.onDateSelected.bind(this);
    this.leftIconClick = this.leftIconClick.bind(this);
    this.sendGoBackCall = this.sendGoBackCall.bind(this);

    var that = this;
    that.resizeCount = 0;
    $(window).resize(function () {
      that.resizeCount++;
      var event = new CustomEvent("AxiomEventInfo", {
        detail: {
          appName: that.props.appName,
          appID: that.props.appID,
          componentID: that.props.componentID,
          componentName: that.state.componentName,
          eventName: "window resize",
          data: { resizeCount: that.resizeCount },
        },
      });
      AxiomAPIs.dispatchEvent(event);
    });
  }

  redirectToApp(data) {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: data.appName + " Clicked",
        data: {},
      },
    });
    AxiomAPIs.dispatchEvent(event);
    window.open(location.origin + data.uri, "_self");
  }

  handleToggle() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: data.appName + " Clicked",
        data: {},
      },
    });
    AxiomAPIs.dispatchEvent(event);
    window.open(location.origin + data.uri);
  }

  logout() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "User logged out",
        data: { logged: false },
      },
    });
    AxiomAPIs.dispatchEvent(event);
    AxiomAPIs.logout(function () {});
    setTimeout(() => {
      location.reload();
    }, 300);
  }

  menuToggle() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "toggleMobileMenuRight",
        data: { mobileMenuState: !this.state.mobileMenuState },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  }

  drawerToggle() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "togglePanel",
        data: { drawerState: !this.state.drawerState },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  }

  leftIconClick() {
    if (this.state.showPrevious) {
      this.sendGoBackCall();
    } else {
      this.drawerToggle();
    }
  }

  onDrawerStateClosed() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "togglePanel",
        data: { drawerState: false },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  }

  onMobileMenuClosed() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "toggleMobileMenuRight",
        data: { mobileMenuState: false },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  }

  openAxiomCalendar() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "Calendar Open",
        data: { calendarState: true },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  }

  onCalendarClosed() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "Calendar Closed",
        data: { calendarState: false },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  }

  sendGoBackCall() {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: "0",
        componentName: "MonitorFirstViews",
        eventName: "Go Back",
        data: {
          goBack: true,
        },
      },
    });

    AxiomAPIs.dispatchEvent(event);
  }

  onDateSelected(date) {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.appBarEventsInfo.componentID,
        componentName: this.props.appBarEventsInfo.componentName,
        eventName: "Calendar Closed",
        data: {
          date: date,
          isFirst: true,
        },
      },
    });

    AxiomAPIs.dispatchEvent(event);

    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "Date Changed",
        data: { date, calendarState: false },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  }

  onMoreMenuItemClick = (item) => {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.appBarEventsInfo.componentID,
        componentName: this.props.appBarEventsInfo.componentName,
        eventName: "More Item Clicked",
        data: { [this.props.showMore.stateName]: item },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  };

  getContext() {
    return {
      axiomTheme:
        typeof this.props.axiomTheme == "string"
          ? theme[this.props.axiomTheme || "axiomWhite"]
          : this.props.axiomTheme,
      appSettings: this.state.appSettings ? this.state.appSettings : {},
      userInfo: {
        name: this.state.userName,
        email: this.state.userEmailID,
        profilePhoto: this.state.profilePhoto,
        lastName: this.state.lastName,
      },
    };
  }

  componentDidMount() {
    if (AxiomAPIs.theme) {
      this.updateTheme(AxiomAPIs.theme);
    }
    if (
      this.state.preferredLanguageMapping &&
      typeof this.state.preferredLanguageMapping == "object"
    ) {
      this.props.i18n.changeLanguage(this.state.preferredLanguageMapping.folderName);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.logged && !this.state.alreadyUpdated) {
      if (this.state.appList == null) {
        var event = new CustomEvent("AxiomEventInfo", {
          detail: {
            appName: this.props.appName,
            appID: this.props.appID,
            componentID: this.props.componentID,
            componentName: this.state.componentName,
            eventName: "mount",
            data: {
              alreadyUpdated: true,
              loading: true,
            },
          },
        });
        AxiomAPIs.dispatchEvent(event);
        const isMobileDevice = Utils.checkMobileDevice(
          this.state.appSetting ? this.state.appSetting.forcefullyDisableMobileView : false
        );
        var event = new CustomEvent("AxiomEvent", {
          detail: {
            appName: this.props.appName,
            appID: this.props.appID,
            componentID: this.props.componentID,
            componentName: this.state.componentName,
            eventName: "mount",
            data: {},
            isMobileDevice: isMobileDevice,
          },
        });
        AxiomAPIs.dispatchEvent(event);
      }
    }
    if (
      this.state.appSettings &&
      this.state.appSettings.theme &&
      this.state.appSettings.theme != prevState.appSettings.theme
    ) {
      this.updateTheme(this.state.appSettings.theme);
    }
    if (
      this.state.preferredLanguageMapping &&
      prevState.preferredLanguageMapping &&
      typeof this.state.preferredLanguageMapping == "object" &&
      this.state.preferredLanguageMapping.folderName !=
        prevState.preferredLanguageMapping.folderName
    ) {
      this.props.i18n.changeLanguage(this.state.preferredLanguageMapping.folderName);
    }
  }

  updateTheme = (theme) => {
    if (theme && theme.length > 0) {
      theme.forEach((item) => {
        document.documentElement.style.setProperty(item.key, item.value);
      });
    }
  };

  onSnackClose = () => {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "mount",
        data: {
          snackOpen: false,
          snackMessage: "",
        },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  };

  resetpreferredLanguage = () => {
    var event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "mount",
        data: {
          preferredLanguage: null,
        },
      },
    });
    AxiomAPIs.dispatchEvent(event);
    this.onDrawerStateClosed();
  };

  preferredLanguageSave = (e, isSaveLanguage, isSelectedLanguage) => {
    e.preventDefault();
    const event = new CustomEvent("AxiomEvent", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "savePreferredLanguage",
        data: { language: isSelectedLanguage.value, isSaveLanguage: isSaveLanguage },
      },
    });
    AxiomAPIs.dispatchEvent(event);
    const savePreferredLanguage = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "Submit without selection",
        data: {
          preferredLanguage: isSelectedLanguage.value,
          preferredLanguageMapping: isSelectedLanguage,
        },
      },
    });
    AxiomAPIs.dispatchEvent(savePreferredLanguage);
  };

  acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    let event = new CustomEvent("AxiomEventInfo", {
      detail: {
        appName: this.props.appName,
        appID: this.props.appID,
        componentID: this.props.componentID,
        componentName: this.state.componentName,
        eventName: "localState",
        data: {
          cookiesAccepted: true,
        },
      },
    });
    AxiomAPIs.dispatchEvent(event);
  };

  render() {
    let colors =
      typeof this.props.axiomTheme == "string"
        ? theme[this.props.axiomTheme || "axiomLight"]
        : this.props.axiomTheme;
    let appDisplayName = "";
    if (this.state.appList && this.state.appList.length) {
      const appconfig = this.state.appList.filter((d) => d.appName == this.props.appName);
      if (appconfig && appconfig.length) {
        appDisplayName = appconfig[0].displayName;
      }
    }

    const isCookieConsentOpen = !this.state.cookiesAccepted && AxiomAPIs.showCookieConsent === true;

    return this.state.logged ? (
      <>
        <div style={{ background: colors.background }} className="axiom-app">
          {this.state.loading && (
            <AxiomLoader background={"rgba(255, 255, 255, 0.4)"} block=".axiom-app" />
          )}
          {this.state.calendarState && (
            <AxiomCalendar
              timePicker={this.props.timePicker}
              date={this.state.date}
              onDateSelected={this.onDateSelected}
              onCalendarClosed={this.onCalendarClosed}
              calendarState={this.state.calendarState}
            />
          )}
          <AxiomAppContext.Provider value={this.getContext()}>
            <AxiomAppBar
              onMoreMenuItemClick={this.onMoreMenuItemClick}
              userLogo={this.state.userLogo}
              user={{ userName: this.state.userName, userEmailID: this.state.userEmailID }}
              title={appDisplayName}
              showPrevious={this.state.showPrevious}
              previousClick={this.previousClick}
              onMobileMenuClosed={this.onMobileMenuClosed}
              onMobileMenuClick={this.menuToggle}
              mobileMenuState={this.state.mobileMenuState}
              onBarIconClick={this.props.barIconClick}
              onMenuClick={this.leftIconClick}
              filterIcon={this.props.filterIcon}
              calendarIcon={this.props.calendarIcon}
              historyIcon={this.props.historyIcon}
              notificationIcon={this.props.notificationIcon}
              notificationCount={this.props.notificationCount}
              onCalendar={this.openAxiomCalendar}
              openFilterPanel={this.props.openFilterPanel}
              appLogo={this.props.appLogo}
              appLogoHeight={this.props.appLogoHeight || 30}
              projectLogo={this.state.projectLogo}
            />
          </AxiomAppContext.Provider>

          <AxiomDrawer
            user={{
              profilePhoto: this.state.profilePhoto,
              userName: this.state.userName,
              userEmailID: this.state.userEmailID,
            }}
            isLogoutDisabled={this.state.isLogoutDisabled}
            isShowChangePassword={this.state.isShowChangePassword}
            logout={this.logout}
            onItemClick={this.redirectToApp}
            appList={this.state.appList}
            appSettings={this.state.appSettings}
            onDrawerStateClosed={this.onDrawerStateClosed}
            toggleDrawer={this.leftIconClick}
            drawerState={this.state.drawerState}
            drawerStyle={this.props.drawerStyle || "normal"}
            {...this.props}
            versionFileContent={this.state.versionFileContent}
            preferredLanguage={this.state.preferredLanguage}
            resetpreferredLanguage={this.resetpreferredLanguage}
          />
          {this.state.supportedLanguages ? (
            !this.state.preferredLanguage &&
            this.state.supportedLanguages.length &&
            this.state.appSettings &&
            this.state.appSettings.enableLanguageSelection ? (
              <AxiomLanguageSelection
                appName={this.props.appName}
                appID={this.props.appID}
                componentID={this.props.componentID}
                preferredLanguageSave={this.preferredLanguageSave}
                preferredLanguage={this.state.preferredLanguage}
                supportedLanguages={this.state.supportedLanguages}
              />
            ) : this.state.axiomLayoutMounted && this.state.renderChildComponent ? (
              <div className="axiom-app-content">
                <AxiomErrorBoundry>
                  <AxiomAppContext.Provider value={this.getContext()}>
                    {this.props.children}
                  </AxiomAppContext.Provider>
                </AxiomErrorBoundry>
              </div>
            ) : null
          ) : null}
          <AxiomSnackBar
            snackOpen={this.state.snackOpen}
            message={this.state.snackMessage}
            type={this.state.snackType}
            onClose={this.onSnackClose}
            autoHideDuration={this.state.autoHideDuration}
            errorCode={this.state.errorCode}
          />
        </div>
        <CookieConsentModal isOpen={isCookieConsentOpen} onAccept={this.acceptCookies} />
      </>
    ) : (
      <>
        <AxiomLoginNew
          appLogo={this.props.appLogo}
          loginLogoHeight={this.props.loginLogoHeight}
          appName={this.props.appName}
          appID={this.props.appID}
          componentID={this.state.componentID}
          includeHeaderText={
            this.props.hasOwnProperty("includeHeaderText") ? this.props.includeHeaderText : true
          }
          includeSubHeaderText={
            this.props.hasOwnProperty("includeSubHeaderText")
              ? this.props.includeSubHeaderText
              : true
          }
          includeAppLogo={
            this.props.hasOwnProperty("includeAppLogo") ? this.props.includeAppLogo : true
          }
          headerText={this.props.headerText}
          subHeaderText={this.props.subHeaderText}
          componentName={this.state.componentName}
        />
        <CookieConsentModal isOpen={isCookieConsentOpen} onAccept={this.acceptCookies} />
      </>
    );
  }
}
