import React, { useLayoutEffect } from "react";
import "./AxiomModal.scss";

export default function AxiomModal(props) {
  const { isOpen, fullScreen, onClose, children, className } = props;

  useLayoutEffect(() => {
    if (!isOpen) {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  // If modal is not open render nothing
  if (!isOpen) {
    return null;
  } else {
    return (
      <div className={"axiom-modal " + (className || "") + (isOpen ? " axiom-modal-is-open" : " ")}>
        <div className="axiom-modal-overlay" onClick={onClose} />
        <div className={`axiom-modal-content ${fullScreen ? "is-fullscreen" : ""}`}>
          <button className="axiom-modal-close" onClick={onClose}>
            &times;
          </button>
          {children}
        </div>
      </div>
    );
  }
}
