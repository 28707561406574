import React, { memo, useEffect, useState } from "react";
import "./ScatterPlot.scss";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { TitleComponent, TooltipComponent } from "echarts/components";
import { ScatterChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import Utils from "__AxiomComponents/Utils.js";

echarts.use([TitleComponent, TooltipComponent, ScatterChart, CanvasRenderer]);

function ScatterPlot(props) {
  const [response, setResponse] = useState({
    data: [
      {
        REVENUE: 5000000,
        NNS: 1000000,
      },
      {
        REVENUE: 303045,
        NNS: 3000000,
      },
    ],
    xAxis: { KPI: "REVENUE", displayName: "Revenue", displayFormat: "0,0.00MillionCaps" },
    yAxis: { KPI: "NNS", displayName: "NNS", displayFormat: "0,0.00MillionCaps" },
  });

  const [option, setOption] = useState({});

  useEffect(() => {
    if (response.data && response.data.length > 0) {
      let scatterData = [];
      response.data.forEach((value) => {
        scatterData.push([value[response.xAxis.KPI], value[response.yAxis.KPI]]);
      });

      if (scatterData && scatterData.length > 0) {
        const newOption = {
          xAxis: {
            name: response.xAxis.displayName,
            axisLabel: {
              formatter: (value) => Utils.apply_format(value, response.xAxis.displayFormat),
            },
          },
          yAxis: {
            name: response.yAxis.displayName,
            axisLabel: {
              formatter: (value) => Utils.apply_format(value, response.yAxis.displayFormat),
            },
            axisTick: {
              show: false, // Hide the ticks on the y-axis
            },
          },
          series: [
            {
              symbolSize: 20,
              data: scatterData,
              type: "scatter",
            },
          ],
          tooltip: {
            trigger: "item",
            backgroundColor: "#f8f8f8",
            borderColor: "#808080",
            axisPointer: {
              type: "none",
            },
            formatter: (params) => {
              const xValue = Utils.apply_format(params.value[0], response.xAxis.displayFormat);
              const yValue = Utils.apply_format(params.value[1], response.yAxis.displayFormat);
              return `${response.xAxis.displayName}: ${xValue}<br>${response.yAxis.displayName}: ${yValue}`;
            },
          },
        };
        setOption(newOption);
      }
    }
  }, [response.data, response.xAxis, response.yAxis]);

  return (
    <div className="ScatterPlot">
      {option && option.series && option.series.length > 0 && (
        <ReactEChartsCore
          option={option}
          notMerge={true}
          lazyUpdate={true}
          echarts={echarts}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {/* {state.loading && <AxiomLoader background={"rgba(255, 255, 255, 0.4)"} block=".ScatterPlot" />} */}
    </div>
  );
}

export default memo(ScatterPlot);
