import React from "react";
import BotResponseLineChart from "./BotResponseLineChart";
import BotResponseBarChart from "./BotResponseBarChart";
import "./BotResponseChart.scss";

const BotResponseChart = (props) => {
  const chart_type = props?.responseObject?.resp?.response?.response_chart?.chart_type || "";

  let chart_type_name = '';

  if (typeof chart_type === 'string') {
    chart_type_name = chart_type.toLowerCase();
  }

  if (!["bar", "line"].includes(chart_type_name)) { 
    chart_type_name = 'none';
  }

  return (
    <div>
      {chart_type_name === "line" && <BotResponseLineChart {...props} />}
      {chart_type_name === "bar" && <BotResponseBarChart {...props} />}
      {chart_type_name === "none" && <>Chart Unavailable</>}
    </div>
  );
};

export default BotResponseChart;
