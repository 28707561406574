import React, { Component, Fragment } from "react";
import AxiomDrawerIcons from "./AxiomDrawerIcons.js";
import "./AxiomDrawer.scss";

export default class AxiomDrawer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  isCurrentApp = (appName) => {
    let currentUrl = window.location.href;
    let urlParts = currentUrl.split("/");
    let appNameFound = urlParts[urlParts.length - 2];
    return appName === appNameFound;
  };

  renderApps = (applist) => {
    return applist.map((app, index) => (
      <a
        key={index}
        tabIndex={0}
        className={`axiom-sidebar-app ${this.isCurrentApp(app.appName) ? "is-active" : ""}`}
        onClick={() => this.props.onItemClick({ uri: app.uri, appName: app.appName })}
      >
        <span className="axiom-sidebar-app__icon">
          {app.iconClassName ? (
            <span className={app.iconClassName} />
          ) : (
            <span className="SmartDrawerAppAvtar">{app.displayName.charAt(0)}</span>
          )}
        </span>
        <span className="axiom-sidebar-app__name">{app.displayName}</span>
      </a>
    ));
  };

  render() {
    let appList =
      this.props.appList &&
      this.props.appList
        .filter((d) => {
          return (d.isSubscribed != 0 || d.isPublic == 1) && d.priority < 100;
        })
        .sort((a, b) => {
          return a.priority - b.priority;
        });
    if (appList && appList.length > 0) {
      appList = appList.slice(0, 5);

      if (
        this.props.appSettings &&
        this.props.appSettings.moreAppsLabelPosition &&
        this.props.appSettings.moreAppsLabelPosition == "top"
      ) {
        appList.unshift({
          appName: "More Apps",
          uri: `/apps/Home/#/all-apps`,
          displayName:
            this.props.appSettings && this.props.appSettings.moreAppsLabel
              ? this.props.appSettings.moreAppsLabel
              : "More Apps",
          priority: 99,
        });
      } else {
        appList.push({
          appName: "More Apps",
          uri: `/apps/Home/#/all-apps`,
          displayName:
            this.props.appSettings && this.props.appSettings.moreAppsLabel
              ? this.props.appSettings.moreAppsLabel
              : "More Apps",
          priority: 99,
        });
      }
    }

    this.props.drawerState
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
    return (
      <Fragment>
        <nav className={"axiom-sidebar " + (this.props.drawerState ? "is-open" : "")}>
          <div className="axiom-sidebar-user">
            {this.props.user.profilePhoto ? (
              <img className="axiom-sidebar-user__avatar" src={this.props.user.profilePhoto} />
            ) : (
              <span className="axiom-sidebar-user__avatar">
                {this.props.user.userName.charAt(0)}
              </span>
            )}
            <div>
              <p className="axiom-sidebar-user__name">{this.props.user.userName}</p>
              <p className="axiom-sidebar-user__email">{this.props.user.userEmailID}</p>
            </div>
          </div>

          <div className="axiom-sidebar-apps">{appList && this.renderApps(appList)}</div>
          <div className="axiom-version-bundle">
            {this.props.versionFileContent ? (
              <div>
                <ul>
                  {this.props.versionFileContent.map((listitem) => (
                    <li className="axiom-version-content">{listitem}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          {this.props.preferredLanguage &&
            this.props.appSettings &&
            this.props.appSettings.enableLanguageSelection && (
              <div className="axiom-sidebar-utilities">
                <a
                  tabIndex={0}
                  onClick={this.props.resetpreferredLanguage}
                  className="axiom-sidebar-utility"
                >
                  <AxiomDrawerIcons.IconChangeLanguage className="axiom-sidebar-utility__icon" />
                  <span className="axiom-sidebar-utility__label">Change Language</span>
                </a>
              </div>
            )}
          {this.props.isShowChangePassword && (
            <div className="axiom-sidebar-utilities">
              <a tabIndex={0} href="/apps/Home/#/change-password" className="axiom-sidebar-utility">
                <AxiomDrawerIcons.IconLogout className="axiom-sidebar-utility__icon" />
                <span className="axiom-sidebar-utility__label">Change Password</span>
              </a>
            </div>
          )}
          {!this.props.isLogoutDisabled && (
            <div className="axiom-sidebar-utilities">
              <a tabIndex={0} onClick={this.props.logout} className="axiom-sidebar-utility">
                <AxiomDrawerIcons.IconChangePasssword className="axiom-sidebar-utility__icon" />
                <span className="axiom-sidebar-utility__label">Logout</span>
              </a>
            </div>
          )}
        </nav>
        <div className="axiom-sidebar-overlay" onClick={this.props.toggleDrawer} />
      </Fragment>
    );
  }
}
