import React, { memo, useEffect, useState } from "react";
import "./Grid.scss";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Utils from "__AxiomComponents/Utils.js";

function Grid(props) {
  const [response, setResponse] = useState({
    data: props.data? props.data.data : [],
    columns: props.data? props.data.headers : [],
  });

  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);

  useEffect(() => {
    let columnDefs = [];
    let gridData = [];
    if (
      response.data &&
      response.columns &&
      response.columns.length > 0 &&
      response.data.length > 0
    ) {
      response.columns.forEach((column, index) => {
        const colDef = { field: column.mapping, minWidth: 200,maxWidth:300 };
        if (index === 0) {
          colDef.cellClass = "first-cell-background";
        }
        if (column.displayFormat) {
          colDef.valueFormatter = (params) => {
            const value = params.value;
            return Utils.apply_format(value, column.displayFormat);
          };
        }
        columnDefs.push(colDef);
      });
      response.data.forEach((value) => {
        gridData.push(value);
      });
    }
    setRowData(gridData);
    setColDefs(columnDefs);
  }, [response]);

  return (
    <div className="Grid">
      {rowData && rowData.length > 0 && colDefs && colDefs.length > 0 && (
        <div className="ag-theme-alpine" >
          <AgGridReact
            rowClass={"ag-row-class"}
            suppressRowTransform={true}
            defaultColDef={{ resizable: true, sortable: true, minWidth: 200, maxWidth:300 }} // Set default minWidth
            rowHeight={26}
            rowData={rowData}
            columnDefs={colDefs}
          />
        </div>
      )}
    </div>
  );
}

export default memo(Grid);
