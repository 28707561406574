import React from "react";
import "./BotResponseScreen.scss";
import BotResponseTable from "./BotResponseTable";
import BotResponseChart from "./BotResponseChart";
import BotResponseSQLScript from "./BotResponseSQLScript";

const BotResponseScreen = (props) => {
  const respJson = props?.responseObject?.resp || {}
  // const handleRespCloseBtn = props?.closeRespFromCloseBtn

  // const handleCloseRespButton = () => {
  //   handleRespCloseBtn();
  //   // props?.closeResponseDetailWindow()
  // }

  // style={"display: none"}

  return (
    <div className="response-detail-screen">
      {/* <button className = "closeButton" onClick={handleCloseRespButton} style={{display: "none"}}>✖</button>  */}
      {Object.keys(respJson)?.length > 0 && <div className="detail-content">
      {props.responseObject?.type === "table" && <BotResponseTable {...props} />}
      {props.responseObject?.type === "chart" && <BotResponseChart {...props} />}
      {props.responseObject?.type === "sql" && <BotResponseSQLScript {...props} />}
      </div>}
    </div>
  );
};

export default BotResponseScreen;