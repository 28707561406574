import React, { Component } from "react";
import AxiomSpinner from "../AxiomSpinner/AxiomSpinner.jsx";
import "./AxiomLoader.css";

class AxiomLoader extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("resize", this.reRender);
    this.isThisMounted = true;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reRender);
    this.isThisMounted = false;
  }

  reRender = () => {
    this.isThisMounted && this.setState({ height: document.body.clientHeight });
  };

  render() {
    let overlayHeight =
      (document.querySelector(this.props.block) &&
        document.querySelector(this.props.block).clientHeight) ||
      0;
    let style = { minHeight: this.props.fullScreen ? "100vh" : overlayHeight };
    if (this.props.background != null) {
      style.backgroundColor = this.props.background;
    }
    return (
      <div
        className={
          "axiom-loader " +
          (this.props.className || "") +
          (this.props.fullScreen ? " axiom-loader-fullscreen" : "")
        }
      >
        <div className="axiom-loader-overlay" style={style}>
          <AxiomSpinner
            text={this.props.text}
            spinnerColor={this.props.spinnerColor}
            spinnerWidth={this.props.spinnerWidth || 60}
          />
        </div>
      </div>
    );
  }
}

export default AxiomLoader;
